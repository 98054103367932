class Club {
  constructor(params) {
    this.clubId = params.clubId;
    this.clubName = params.clubName;
    this.clubNameShort = params.clubNameShort;
    this.mapsLink = params.mapsLink;
    this.email = params.email;
    this.loginText = params.loginText;
    this.dashboardMessage = params.dashboardMessage;
    this.address = params.address;
    this.introduction = params.introduction;
    this.standardPackage = params.standardPackage;
    this.corporatePackage = params.corporatePackage;
    this.quotes = params.quotes;
    this.explainerImages = params.explainerImages;
    this.renew = params.renew;
    this.renewButton = params.renewButton;
    this.numberOfGuests = params.numberOfGuests;
    this.topup = params.topup;
    this.termsAndConditions = params.termsAndConditions;
  }
}

export { Club };
