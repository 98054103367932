import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

// this will want lazy loading one day
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.js$/i);
  let mergedMessages = {};
  locales.keys().forEach((key) => {
    const newMergedMessages = { ...mergedMessages, ...locales(key).default };
    mergedMessages = newMergedMessages;
  });
  return { ...mergedMessages };
}


// this section taken from   https://blog.logrocket.com/advanced-localization-techniques-vue-js/
function checkDefaultLanguage() {
  const prexistingSetting = JSON.parse(localStorage.getItem('vuex'));

  // I'm not sure how it was supposed to magically know in that guys code but i added this to actually check localstorage?
  if (prexistingSetting != null) {
    if (prexistingSetting.user != null) {
      if (prexistingSetting.user.locale != null) {
        return prexistingSetting.user.locale; // this is obviously a bad way to write this but it works
      }
    }
  }

  let matched = null;
  const languages = Object.getOwnPropertyNames(loadLocaleMessages());

  languages.forEach((lang) => {
    if (lang === navigator.language) {
      matched = lang;
    }
  });
  if (!matched) {
    languages.forEach((lang) => {
      const languagePartials = navigator.language.split('-')[0];
      if (lang === languagePartials) {
        matched = lang;
      }
    });
  }
  if (!matched) {
    languages.forEach((lang) => {
      const languagePartials = navigator.language.split('-')[0];
      if (lang.split('-')[0] === languagePartials) {
        matched = lang;
      }
    });
  }

  // hack for now because we aren't in america
  if (matched && matched === 'en-US') {
    matched = 'en';
  }
  return matched;
}
export const selectedLocale = checkDefaultLanguage() || process.env.VUE_APP_I18N_LOCALE || 'en';
export const languages = Object.getOwnPropertyNames(loadLocaleMessages());

// end that section

export default new VueI18n({
  locale: selectedLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
});
