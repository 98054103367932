// import moment from 'moment';
import { services } from '../services';


const stateStore = {
  state: {
    blackFriday: {
      State: 'Hide',
      EarlyAccessStart: '2021-11-24T20:00:00Z',
      Discount: 15,
    },
    twelveDaysOfChristmas: {
      StartDate: '2021-12-13T09:00:00Z',
      EndDate: '2021-12-24T23:59:00Z',
    },
    hasCompletedTour: false,
  },

  getters: {
    isBFHide: state => state.blackFriday.State === 'Hide',
    isBFRegister: state => state.blackFriday.State === 'EarlyAccessRegister',
    isBFEarlyAccess: state => state.blackFriday.State === 'EarlyAccessStart',
    isBF: state => state.blackFriday.State === 'BlackFridayStart',
    isTDC: state => new Date() < new Date(state.twelveDaysOfChristmas.EndDate) && new Date() > new Date(state.twelveDaysOfChristmas.StartDate),
    isTDCStart: state => new Date() > new Date(state.twelveDaysOfChristmas.StartDate),
    isTDCEnd: state => new Date() < new Date(state.twelveDaysOfChristmas.EndDate),
  },

  mutations: {
    pushBlackFridayState(state, value) {
      state.blackFriday = value;
    },
    pushTutorialState(state, value) {
      state.hasCompletedTour = value;
    },
  },

  actions: {
    updateTutorialState(context, value) {
      context.commit('pushTutorialState', value);
    },

    updateStates(context) {
      return new Promise(() => {
        services.state.getBlackFridayState().then((result) => {
          context.commit('pushBlackFridayState', result);
        });
      });
    },
  },
};

export { stateStore };
