const accountRoutes = [
  {
    path: '/profile',
    component: () => import('@/app/account/profile.vue'),
    meta: {
      requiresAuth: true,
      isWindowView: true,
      windowViewTitle: 'Profile',
    },
    children: [
      {
        path: '/',
        name: 'profile',
        component: () => import('@/app/shared/components/account/accountDetails.vue'),
        meta: {
          title: 'Profile',
          requiresAuth: true,
          isWindowView: true,
          windowViewTitle: 'Profile',
        },
      },
      {
        path: '/stats',
        name: 'stats',
        component: () => import('@/app/member/statistics.vue'),
        meta: {
          title: 'Statistics',
          requiresAuth: true,
          isWindowView: true,
          windowViewTitle: 'Statistics',
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./login/login.vue'),
    meta: {
      title: 'Login',
      requiresAuth: false,
      hideFooter: true,
    },
  },
  {
    path: '/login/:clubName/:clubId',
    name: 'loginForClub',
    component: () => import('./login/login.vue'),
    meta: {
      title: 'Login',
      overrideStyle: true,
      requiresAuth: false,
      hideFooter: true,
    },
  },
  {
    path: '/2FA/:TFAToken/:UseFallbackUI',
    name: '2FA',
    component: () => import('./2FA/2FA.vue'),
    meta: {
      title: 'Two-Factor Authentication',
      requiresAuth: false,
      noLayout: true,
      hideFooter: true,
    },
  },
  {
    path: '/arrears',
    name: 'arrears',
    component: () => import('./arrears/arrears.vue'),
    meta: {
      title: 'Account Locked',
      noLayout: true,
    },
  },
  {
    path: '/login/*',
    name: 'loginBad',
    redirect: 'login',
  },
];

export default accountRoutes;
