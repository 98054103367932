<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <footer
    v-show="!$isMobileApp"
    class=" relative bg-white  z-10 min-h-32"
  >
    <div class="max-w-5xl mx-auto pt-6  overflow-hidden ">
      <div
        v-show="!$isMobile.value && !$isNine"
        class="flex justify-between"
      >
        <nav
          class="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          <div
            v-for="item in topItems"
            :key="item.name"
            class="px-5 py-2"
          >
            <a
              :href="item.href"
              class="text-base text-gray-500 hover:text-gray-900"
            >
              {{ item.name }}
            </a>
          </div>
        </nav>
        <nav
          class="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          <div
            class="px-5 py-2"
          >
            <a
              href="https://www.facebook.com/PlayMoreGolfUK"
              class="text-base text-gray-500 hover:text-gray-900"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                class="h-5 w-5 text-brand-500 fill-current"
              >
                <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
              </svg>
            </a>
          </div>
          <div
            class="px-5 py-2"
          >
            <a
              href="https://twitter.com/playmore_golf"
              class="text-base text-gray-500 hover:text-gray-900"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="h-5 w-5 text-brand-500 fill-current"
              >
                <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
              </svg>
            </a>
          </div>
          <div
            class="px-5 py-2"
          >
            <a
              href="https://www.youtube.com/channel/UCJ4389YkWwoheD5h2YfjB_A/feed"
              class="text-base text-gray-500 hover:text-gray-900"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                class="h-5 w-5 text-brand-500 fill-current"
              >
                <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
              </svg>
            </a>
          </div>
        </nav>
      </div>
      <div
        v-show="$isStandard && $isMobile.value"
        class="text-center pb-3 font-semibold px-2"
      >
        Download the official PlayMoreGolf app for the best mobile experience!
      </div>
      <hr
        v-show="$isStandard && $isMobile.value"
        class="border-gray-600 border-top border-dashed mt-6 mb-6"
      >
      <div
        v-if="$isStandard && $isMobile.value"
        class="flex justify-between items-center space-x-10 px-4"
      >
        <a
          href="https://apps.apple.com/us/app/playmoregolf/id1459396455"
        >
          <img
            v-local-img="'googleplaylogo.png'"
            class="h-16 p-2"
            alt="Get it on Google Play"
          >
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=golf.playmore.caddy&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        >
          <img
            v-local-img="'Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'"
            class="h-16 p-2"
            alt="Get it on iOS"
          >
        </a>
      </div>
      <hr
        v-if="$isStandard"
        class="border-gray-600 border-top border-dashed mt-6"
      >
      <div :class="{'flex justify-between': !$isMobile.value}">
        <div
          v-show="!$isMobile.value && $isStandard"
          class=" -mx-5 -my-2 py-6 text-sm  text-gray-400"
        >
          <p class="px-5 py-2">
            &copy; 2022 Play More Golf Limited
          </p>
        </div>
        <div
          class="-mx-5 -my-2 py-6 text-sm flex flex-wrap justify-center"
          :class="{'justify-around w-full': !$isStandard}"
        >
          <div
            class="px-5 py-2"
          >
            <a
              href="https://www.playmore.golf/terms-and-conditions/"
              class="text-gray-500 hover:text-gray-900"
            >
              Terms & Conditions
            </a>
          </div>
          <div
            class="px-5 py-2"
          >
            <a
              href="https://www.playmore.golf/cookie-policy/"
              class="text-gray-500 hover:text-gray-900"
            >
              Cookie Policy
            </a>
          </div>
          <div
            class="px-5 py-2"
          >
            <a
              href="https://www.playmore.golf/privacy-policy/"
              class="text-gray-500 hover:text-gray-900"
            >
              Privacy Policy <span v-show="!$isMobile.value"> & Data Protection</span>
            </a>
          </div>
        </div>
      </div>
      <hr
        v-show="$isMobile.value"
        class="border-gray-600 border-top border-dashed pb-2"
      >
    </div>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter',

  computed: {
    topItems() {
      return [
        { name: 'About', href: 'https://www.playmore.golf/about-us/' },
        { name: 'Blog', href: 'https://blog.playmore.golf/golfers' },
        { name: 'Contact', href: 'https://www.playmore.golf/contact/' },
        { name: 'Jobs', href: 'https://www.playmore.golf/careers/' },
      ];
    },
  },
};
</script>
