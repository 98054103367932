const memberRoutes = [
  {
    path: '/membership/renew',
    name: 'membershipRenew',
    component: () => import('@/app/renew/renew.vue'),
    meta: {
      title: 'Membership Packages',
      requiresAuth: true,
      isWindowView: true,
      windowViewTitle: 'Membership Package',
      hideFooter: true,
    },
  },
  {
    path: '/membership/packages',
    name: 'membershipPackages',
    component: () => import('@/app/renew/renew.vue'),
    meta: {
      title: 'Membership Packages',
      requiresAuth: true,
      isWindowView: true,
      windowViewTitle: 'Membership Package',
      hideFooter: true,
    },
  },
  {
    path: '/membership/ixrenew',
    name: 'nineRenew',
    component: () => import('@/app/renew/renew.vue'),
    meta: {
      title: 'Membership Package',
      requiresAuth: true,
      isWindowView: true,
      noLayout: true,
      windowViewTitle: 'Membership Package',
    },
  },
  {
    path: '/membership/renew/:clubId',
    name: 'membershipRenewAtClub',
    component: () => import('@/app/renew/renew.vue'),
    meta: {
      title: 'Membership Package',
      requiresAuth: true,
      isWindowView: true,
      windowViewTitle: 'Membership Package',
      hideFooter: true,
    },
  },
  {
    path: '/membership-pack',
    name: 'MembershipPack',
    component: () => import('./membershipPack.vue'),
    meta: {
      title: 'Membership Pack',
      requiresAuth: true,
      isWindowView: true,
      windowViewTitle: 'Membership Pack',
      hideFooter: true,
    },
  },
  {
    path: '/trophies',
    name: 'Trophies',
    component: () => import('@/app/trophies/trophies.vue'),
    meta: {
      requiresAuth: true,
      isWindowView: true,
      windowViewTitle: 'Trophies',
    },
    children: [
      {
        path: '/',
        name: 'trophyProgress',
        component: () => import('@/app/trophies/progress.vue'),
        meta: {
          title: 'Trophies',
          isWindowView: true,
          windowViewTitle: 'Trophies',
        },
      },
      {
        path: '/leaderboard',
        name: 'trophyLeaderboard',
        component: () => import('@/app/trophies/leaderboard.vue'),
        meta: {
          title: 'Trophy Leaderboard',
          isWindowView: true,
          windowViewTitle: 'Trophies',
        },
      },
    ],
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('./history.vue'),
    meta: {
      requiresAuth: true,
      isWindowView: true,
    },
    children: [
      {
        path: '/',
        name: 'historicalBookings',
        component: () => import('@/app/shared/components/account/bookingHistory.vue'),
        meta: {
          title: 'Booking History',
          isWindowView: true,
          windowViewTitle: 'History',
        },
      },
      {
        path: '/historical-transactions',
        name: 'historicalTransactions',
        component: () => import('@/app/shared/components/account/transactionHistory.vue'),
        meta: {
          title: 'Historical Transactions',
          isWindowView: true,
          windowViewTitle: 'History',
        },
      },
    ],
  },
  {
    path: '/morebenefits',
    name: 'MoreBenefits',
    component: () => import('@/app/morebenefits/morebenefits.vue'),
    meta: {
      title: 'MoreBenefits',
      windowViewTitle: 'MoreBenefits',
      requiresAuth: true,
      isWindowView: true,
    },
  },
];

export default memberRoutes;
