const halfSecondish = 300;

const layoutStore = {
  state: {
    openNotificationMenu: false,
    openBasketMenu: false,
    refreshingBasket: false,
    openSideMenu: false,
    sideMenuStateLastChanged: undefined,
    notificationMenuStateLastChanged: undefined,
    basketMenuStateLastChanged: undefined,
  },

  mutations: {
    switchSideMenuState(state) {
      this.commit('setSideMenuState', !state.openSideMenu);
    },

    setSideMenuState(state, value) {
      const timeNow = new Date();
      if (state.sideMenuStateLastChanged === undefined || timeNow - state.sideMenuStateLastChanged > halfSecondish) {
        state.openSideMenu = value;
        state.sideMenuStateLastChanged = timeNow;
      }
    },

    switchNotificationMenuState(state) {
      this.commit('setNotificationMenuState', !state.openNotificationMenu);
    },

    setNotificationMenuState(state, value) {
      const timeNow = new Date();
      if (state.notificationMenuStateLastChanged === undefined || timeNow - state.notificationMenuStateLastChanged > halfSecondish) {
        state.openNotificationMenu = value;
        state.notificationMenuStateLastChanged = timeNow;
      }
    },
    switchBasketMenuState(state) {
      this.commit('setBasketMenuState', !state.openBasketMenu);
    },

    setBasketMenuState(state, value) {
      const timeNow = new Date();
      if (state.basketMenuStateLastChanged === undefined || timeNow - state.basketMenuStateLastChanged > halfSecondish) {
        state.openBasketMenu = value;
        state.basketMenuStateLastChanged = timeNow;
      }
    },

    setRefreshingBasket(state, value) {
      state.refreshingBasket = value;
    },
  },

  actions: {
    async setNotificationMenuState({ commit }, value) {
      commit('setNotificationMenuState', value);
    },

    async switchNotificationMenuState({ commit }) {
      commit('switchNotificationMenuState');
    },

    async setSideMenuState({ commit }, value) {
      commit('setSideMenuState', value);
    },

    async switchSideMenuState({ commit }) {
      commit('switchSideMenuState');
    },

    async setBasketMenuState({ commit }, value) {
      commit('setBasketMenuState', value);
    },

    async switchBasketMenuState({ commit }) {
      commit('switchBasketMenuState');
    },
  },
};

export { layoutStore };
