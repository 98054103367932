<template>
  <div>
    <div
      class="h-16 bg-secondary-500 border-b-0 items-center justify-between select-none hidden md:flex md:fixed top-0 right-0 w-full md:z-40 md:shadow-lg"
    >
      <router-link
        :to="isAuthenticated ? '/' : '/login'"
        class="w-3/12 flex items-center justify-start"
      >
        <div>
          <div
            v-if="$isStandard"
            class="flex items-center justify-center"
            style="width: 5.2rem"
          >
            <div>
              <div class="flex">
                <div class="w-3 h-3" />
                <div class="w-3 h-3 bg-green-600 shadow" />
                <div class="w-3 h-3" />
              </div>
              <div class="flex">
                <div class="w-3 h-3 bg-green-600 shadow" />
                <div class="w-3 h-3 bg-green-100 shadow" />
                <div class="w-3 h-3 bg-green-700 shadow" />
              </div>
              <div class="flex">
                <div class="w-3 h-3" />
                <div class="w-3 h-3 bg-green-700 shadow" />
                <div class="w-3 h-3" />
              </div>
            </div>
          </div>
        </div>
      </router-link>
      <div
        class="max-w-5xl w-full pl-5 ml-12"
      >
        <MobileMenuBar
          :border-location="'border-b-4'"
        />
      </div>
      <div
        v-if="isAuthenticated"
        class="w-3/12 mr-5 flex items-center justify-end"
      >
        <div v-if="isAdmin">
          <a :href="adminUrl">
            <div
              class="relative bg-brand-500 rounded-full py-4 w-10 h-10 flex items-center justify-center text-white hover:text-gray-100 cursor-pointer transition-all duration-500"
              data-tooltip="Admin Dashboard"
              data-tooltip-location="bottom"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                class="w-4 h-4 fill-current"
              ><path d="M128 216C128 238.1 110.1 256 88 256H40C17.91 256 0 238.1 0 216V168C0 145.9 17.91 128 40 128H88C110.1 128 128 145.9 128 168V216zM128 376C128 398.1 110.1 416 88 416H40C17.91 416 0 398.1 0 376V328C0 305.9 17.91 288 40 288H88C110.1 288 128 305.9 128 328V376zM160 168C160 145.9 177.9 128 200 128H248C270.1 128 288 145.9 288 168V216C288 238.1 270.1 256 248 256H200C177.9 256 160 238.1 160 216V168zM288 376C288 398.1 270.1 416 248 416H200C177.9 416 160 398.1 160 376V328C160 305.9 177.9 288 200 288H248C270.1 288 288 305.9 288 328V376zM320 168C320 145.9 337.9 128 360 128H408C430.1 128 448 145.9 448 168V216C448 238.1 430.1 256 408 256H360C337.9 256 320 238.1 320 216V168zM448 376C448 398.1 430.1 416 408 416H360C337.9 416 320 398.1 320 376V328C320 305.9 337.9 288 360 288H408C430.1 288 448 305.9 448 328V376z" /></svg>
            </div>
          </a>
        </div>
        <div v-else>
          <div
            v-if="basket !== null && basket !== undefined || $isNine"
            class="relative bg-gray-200 rounded-full py-4 w-10 h-10 flex items-center justify-center text-gray-500 hover:text-secondary-500 cursor-pointer transition-all duration-500"
            data-tooltip="Basket"
            data-tooltip-location="bottom"
            @click="switchBasketMenuState()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="shopping-basket"
              class="w-4 h-4"
              role="img"
              viewBox="0 0 576 512"
            ><path
              fill="currentColor"
              d="M576 216v16c0 13.255-10.745 24-24 24h-8l-26.113 182.788C514.509 462.435 494.257 480 470.37 480H105.63c-23.887 0-44.139-17.565-47.518-41.212L32 256h-8c-13.255 0-24-10.745-24-24v-16c0-13.255 10.745-24 24-24h67.341l106.78-146.821c10.395-14.292 30.407-17.453 44.701-7.058 14.293 10.395 17.453 30.408 7.058 44.701L170.477 192h235.046L326.12 82.821c-10.395-14.292-7.234-34.306 7.059-44.701 14.291-10.395 34.306-7.235 44.701 7.058L484.659 192H552c13.255 0 24 10.745 24 24zM312 392V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm112 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm-224 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24z"
            /></svg>
            <div
              v-if="basket !== null && basket !== undefined"
              class="inline-block text-xs text-white bg-red-700 absolute leading-none cursor-pointer select-none"
              style="top: 3px; right: -5px; padding: 2px 5px; border-radius: 50%;"
            >
              1
            </div>

            <BasketMenu
              v-if="openBasketMenu"
              v-click-outside="closeBasketMenu"
              :enable-squeeze="enableSqueeze"
            />
          </div>
          <div
            v-else
            id="v-step-0"
            class="relative bg-gray-200 rounded-full py-4 w-10 h-10 flex items-center justify-center text-gray-500 hover:text-secondary-500 cursor-pointer transition-all duration-500"
            data-tooltip="Website Tour"
            data-tooltip-location="bottom"
            @click="startTour()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
              class="w-4 h-4 fill-current"
            ><path d="M144 48C144 21.49 165.5 0 192 0C218.5 0 240 21.49 240 48C240 74.51 218.5 96 192 96C165.5 96 144 74.51 144 48zM152 512C134.3 512 120 497.7 120 480V256.9L91.43 304.5C82.33 319.6 62.67 324.5 47.52 315.4C32.37 306.3 27.47 286.7 36.58 271.5L94.85 174.6C112.2 145.7 143.4 128 177.1 128H320V48C320 21.49 341.5 .0003 368 .0003H592C618.5 .0003 640 21.49 640 48V272C640 298.5 618.5 320 592 320H368C341.5 320 320 298.5 320 272V224H384V256H576V64H384V128H400C417.7 128 432 142.3 432 160C432 177.7 417.7 192 400 192H264V480C264 497.7 249.7 512 232 512C214.3 512 200 497.7 200 480V352H184V480C184 497.7 169.7 512 152 512L152 512z" /></svg>
          </div>
        </div>

        <div
          class="relative bg-gray-200 rounded-full py-4 w-10 h-10 flex items-center justify-center ml-3 text-gray-500 hover:text-secondary-500 cursor-pointer transition-all duration-500"
          data-tooltip="Notifications"
          data-tooltip-location="bottom"
          @click="openNotifications()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="bell"
            class="w-4 h-4"
            role="img"
            viewBox="0 0 448 512"
          ><path
            fill="currentColor"
            d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"
          /></svg>
          <div
            v-if="notificationCount > 0"
            class="inline-block text-xs text-white bg-red-700 absolute leading-none cursor-pointer select-none"
            style="top: 3px; right: -5px; padding: 2px 5px; border-radius: 50%;"
          >
            {{ notificationCount }}
          </div>

          <NotificationMenu
            v-if="openNotificationMenu"
            v-click-outside="closeNotifications"
            :notifications="notifications"
            @close="$store.dispatch('setNotificationMenuState', false)"
          />
        </div>
        <div
          v-if="!IsImpersonating"
          class="bg-gray-200 rounded-full py-4 w-10 h-10 flex items-center justify-center ml-3 text-gray-500 hover:text-secondary-500 cursor-pointer transition-all duration-500"
          data-tooltip="Logout"
          data-tooltip-location="bottom"
          @click="openLogoutConfirmModal = true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="sign-out-alt"
            class="w-4 h-4"
            role="img"
            viewBox="0 0 512 512"
          ><path
            fill="currentColor"
            d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"
          /></svg>
        </div>
        <div
          v-else
          class="bg-red-600 rounded-full py-4 w-10 h-10 flex items-center justify-center ml-3 text-white hover:bg-red-500 cursor-pointer transition-all duration-500"
          data-tooltip="Stop Impersonating"
          data-tooltip-location="bottom"
          @click="stopImpersonating()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="sign-out-alt"
            class="w-4 h-4"
            role="img"
            viewBox="0 0 512 512"
          ><path
            fill="currentColor"
            d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"
          /></svg>
        </div>
      </div>
    </div>

    <WarningConfirmModal
      v-if="openLogoutConfirmModal"
      :title="$t('commonUi.logout')"
      :message="$t('logout.areYouSure')"
      @result="logoutResult"
    />

    <transition name="fade">
      <NotificationPopup
        v-if="openNotificationPopup"
        :notification="notificationPopup"
        @click="closePopup"
      />
    </transition>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import WarningConfirmModal from '@/app/shared/components/base/modal/warningConfirmModal.vue';
import NotificationMenu from '@/app/shared/components/notification/notificationMenu.vue';
import { Notification } from 'pmg-services';
import BasketMenu from '@/app/shared/components/basket/basketMenu.vue';
import { services } from '@/app/shared/services';
import { hubConnection } from 'signalr-no-jquery';
import { environment } from '@/environment/environment';
import NotificationPopup from '@/app/shared/components/notification/notificationPopup.vue';
import MobileMenuBar from '../mobileMenuBar/mobileMenuBar.vue';

export default {
  name: 'DesktopHeader',

  components: {
    MobileMenuBar,
    WarningConfirmModal,
    BasketMenu,
    NotificationMenu,
    NotificationPopup,
  },

  data() {
    return {
      openLogoutConfirmModal: false,
      notifications: null,
      notificationCount: 0,

      openNotificationPopup: false,
      openAcceptBuddy: false,
      openDeclineBuddy: false,

      notificationPopup: {
        type: 1,
        action: 0,
        object: {
          Title: 'Trophy Unlocked',
          Description: 'You have unlocked the Enthusiast trophy',
        },
      },
    };
  },

  computed: {
    ...mapState({
      openBasketMenu: state => state.layout.openBasketMenu,
      openNotificationMenu: state => state.layout.openNotificationMenu,
      squeezePage: state => state.theme.squeezePage,
      IsExpired: state => state.user.IsExpired,
      completedFirstPayment: state => state.user.completedFirstPayment,
      IsImpersonating: state => state.user.IsImpersonating,
      basket: state => state.basket.basket,
      userGuid: state => state.user.userGuid,
      isAuthenticated: state => state.user.isAuthenticated,
      isAdmin: state => state.user.isAdmin,
    }),

    enableSqueeze() {
      if (!this.squeezePage && !this.IsExpired && this.completedFirstPayment) {
        return false;
      }
      return true;
    },

    adminUrl() {
      return process.env.VUE_APP_ADMIN_URL;
    },
  },

  mounted() {
    if (this.isAuthenticated) {
      this.getNumberOfBuddyRequests();
      this.getNumberOfUnreadNotifications();
      this.initializeSignalR();
    }

    this.$emit('startTour');
  },

  methods: {
    initializeSignalR() {
      const connection = hubConnection(`${environment}/signalr/notificationHub`);
      const hubProxy = connection.createHubProxy('notificationHub');

      const vm = this;
      hubProxy.on('addNewMessageToPage', (title, desc, type, action, id) => {
        const notification = new Notification(id, null, false, null, null, type, action, { Title: title, Description: desc });
        vm.notificationReceived(notification);
      });

      connection.start();
    },

    notificationReceived(notification) {
      console.log(notification);
      if (notification.type !== 1 || this.$isStandard) {
        this.notificationCount += 1;
        this.notificationPopup = notification;
        this.openNotificationPopup = true;

        setTimeout(() => {
          this.openNotificationPopup = false;
        }, 10000);
      }
    },

    closePopup() {
      this.openNotificationPopup = false;
    },

    startTour() {
      this.$tours.welcomeTour.start();
    },

    stopImpersonating() {
      services.account.stopImpersonating().then(() => {
        const url = `${process.env.VUE_APP_ADMIN_URL}/#!/memberInfo?guid=${this.userGuid}`;

        window.location.assign(url);
        window.location = url;
      });
    },

    switchBasketMenuState() {
      this.$store.dispatch('switchBasketMenuState');
      this.$store.dispatch('setNotificationMenuState', false);
    },

    closeBasketMenu() {
      this.$store.dispatch('setBasketMenuState', false);
    },

    openNotifications() {
      this.$store.dispatch('setBasketMenuState', false);

      this.$store.dispatch('setNotificationMenuState', !this.openNotificationMenu);
      this.openNotificationPopup = false;

      if (this.notificationCount > 0) {
        this.notificationCount = 0;
        services.notification.allNotificationsAsRead();
      }
    },

    closeNotifications() {
      this.$store.dispatch('setNotificationMenuState', false);
    },

    getNumberOfBuddyRequests() {
      services.buddy.buddyRequestCount().then((data) => {
        this.buddyRequests = data;
      });
    },

    getNumberOfUnreadNotifications() {
      services.notification.unreadNotificationCount().then((data) => {
        this.notificationCount = data;
      });
    },

    logoutResult(result) {
      if (result) {
        this.openLogoutConfirmModal = false;
        this.logout();
      } else {
        this.openLogoutConfirmModal = false;
      }
    },

    logout() {
      services.account.logout().then(() => {
        try {
          this.$tours.welcomeTour.stop();
        } catch (err) {
          console.log(err);
        }

        this.$store.commit('toggleHideGlobalLoader', true);
        this.$store.dispatch('setAuth', false);
        this.$store.dispatch('emptyMemberSummary');
        this.$store.dispatch('emptyPackages');
        this.$store.dispatch('emptyBuddyStore');

        this.$router.push({ name: 'login' });
      });
    },
  },
};

</script>
