import { services } from '../services';

const basketStore = {
  state: {
    basket: undefined,
  },

  getters: {
    getBasket: state => state.basket,
  },

  mutations: {
    pushBasket(state, basket) {
      state.basket = basket;
    },
  },

  actions: {
    setBasketBundle(context, bundle) {
      return new Promise((resolve) => {
        context.commit('setRefreshingBasket', true, { root: true });
        services.checkout.emptyBasket().then(() => {
          services.checkout.setBasket(bundle.BundleId, bundle.PromoCode, bundle.NewClubId, bundle.AdditionalBundleIds).then(() => {
            services.checkout.getBasket().then((basket) => {
              context.commit('pushBasket', basket);
              context.commit('setRefreshingBasket', false, { root: true });

              resolve();
            });
          });
        });
      });
    },

    refreshBasket(context) {
      return new Promise((resolve) => {
        context.commit('setRefreshingBasket', true, { root: true });
        services.checkout.getBasket().then((basket) => {
          context.commit('pushBasket', basket);
          context.commit('setRefreshingBasket', false, { root: true });
          resolve();
        });
      });
    },

    emptyBasket(context) {
      return new Promise((resolve) => {
        context.commit('setRefreshingBasket', true, { root: true });
        services.checkout.emptyBasket().then(() => {
          services.checkout.getBasket().then((basket) => {
            context.commit('pushBasket', basket);
            context.commit('setRefreshingBasket', false, { root: true });
            resolve();
          });
        });
      });
    },
  },
};

export { basketStore };
