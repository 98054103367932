<template>
  <div>
    <div
      class="z-50 leading-normal text-gray-600"
      :class="enableSqueeze?'basketSqueeze':'basket'"
    >
      <div class="bg-white border border-b-0 shadow-lg md:rounded-lg overflow-hidden">
        <div
          class="border-b p-2 pl-3 pr-3 tracking-wide"
          style="background: linear-gradient(to bottom, rgba(255,255,255,1), rgba(240,240,240,1));"
        >
          Basket
        </div>

        <div>
          <div
            v-if="basket === null || basket === undefined"
            class="text-center p-3"
          >
            Empty
          </div>

          <div v-else>
            <div
              class="p-3"
            >
              <div class="brand-heading ">
                {{ basket.ClubName }}
              </div>

              <div class="border-b pb-4">
                <div class="flex justify-between mt-2 tracking-wide text-gray-900">
                  <div
                    v-if="basket.PackageType === 0"
                    id="packageDuration"
                  >
                    12 Month Package
                  </div>
                  <div v-if="basket.PackageType === 1">
                    {{ basket.NewHomePoints }} <span v-if="false">Home </span>Points
                  </div>
                  <div v-if="basket.PackageType === 2">
                    {{ basket.NewAwayPoints }} Flexi Points
                  </div>

                  <div id="cost">
                    {{ basket.Currency.CurrencySymbol }}{{ basket.NewPrice.toLocaleString() }}
                  </div>
                </div>

                <div class="flex justify-between text-sm text-gray-600 leading-tight">
                  <div v-if="basket.PackageType === 0">
                    Valid until {{ $moment(basket.MembershipExtension.ExtendedRenewalDate).format('Do MMMM YYYY') }}.
                  </div>
                  <div v-if="basket.PackageType === 1">
                    Additional <span v-if="false">Home </span>points can be spent on rounds of golf at your home club.
                  </div>
                  <div v-if="basket.PackageType === 2">
                    Additional Flexi Points can be spent on rounds of golf at your home club and any other club in the network.
                  </div>
                  <!-- <div
                    class="removeFromBasket cursor-pointer ml-3 transition-all duration-500"
                    :class="refreshingBasket?'text-gray-400':'text-gray-800 hover:text-gray-600'"
                    @click="emptyBasket()"
                  >
                    remove
                  </div> -->
                </div>
              </div>

              <div
                id="checkout"
                class="brand-button mt-4"
                @click="checkout()"
              >
                Checkout
              </div>

              <div
                class="brand-button-secondary mt-2"
                @click="emptyBasket()"
              >
                Empty Basket
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {

  components: {

  },

  props: {
    enableSqueeze: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      refreshingBasket: false,
    };
  },

  computed: {
    ...mapState({
      basket: state => state.basket.basket,
      homeClubId: state => state.user.homeClubId,
    }),

    ...mapGetters({
      getMemberExpiryInfo: 'getMemberExpiryInfo',
    }),
  },

  mounted() {

  },

  methods: {

    checkout() {
      // if they were expired they wouldn't have home points theoretically, hence this only looks at suspension period
      // though maybe it should do unexpired members too at some point?
      if (this.homeClubId !== this.basket.NewClubId && this.basket.NewClubId !== null && this.getMemberExpiryInfo.inSuspensionPeriod()) {
        this.$router.push('/membership/transfer');
      } else if (this.$isNine) {
        this.$router.push({ name: 'ninePayment' });
      } else {
        this.$router.push('/membership/payment');
      }
      this.$store.dispatch('setBasketMenuState', false);
    },

    emptyBasket() {
      this.refreshingBasket = true;
      this.$store.dispatch('emptyBasket').then(() => {
        this.refreshingBasket = false;
      });
    },
  },
};

</script>


<style>
@media (min-width: 768px){
  .basket {
    position: absolute;
    top: 35px;
    right: -51px;
    width: 380px;
  }

  .basket:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    color: transparent;
    border: 10px solid rgb(0, 0, 0);
    border-color: transparent transparent #e2e8f0;
    margin-left: 300px;
    margin-top: -5px;
  }

}

@media (max-width: 768px){
  .basket {
    position: fixed;
    top: 60px;
    left: 0px;
    width: 100vw;
  }

  .basket:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    color: transparent;
    border: 10px solid rgb(0, 0, 0);
    border-color: transparent transparent transparent;
    margin-left: 136px;
    margin-top: -20px;
  }

}

@media (min-width: 768px){
  .basketSqueeze {
    position: absolute;
    top: 47px;
    right: -60px;
    width: 380px;
  }

  .basketSqueeze:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    color: transparent;
    border: 10px solid rgb(0, 0, 0);
    border-color: transparent transparent #e2e8f0;
    margin-left: 300px;
    margin-top: -5px;
  }
}

@media (max-width: 768px){
  .basketSqueeze {
    position: fixed;
    top: 60px;
    left: 0px;
    width: 100vw;
  }
}

</style>
