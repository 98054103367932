
<template>
  <div
    class="w-full min-w-full h-full min-h-screen antialiased overscroll-contain text-base"
    :class="getClasses()"
    style="background-color: rgb(247, 247, 247); scroll-behavior: auto !important;"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="showLoadingSpinner"
        key="1"
        class="w-full min-w-full h-full min-h-full flex items-center justify-center"
      >
        <div class="loader" />
      </div>

      <div
        v-else
        key="2"
        class="flex items-stretch overflow-x-hidden"
        :class="environment === 'cordova' ? 'noSelect' : ''"
      >
        <div
          class="flex items-stretch h-full min-h-screen"
          style="width: 175vw;"
        >
          <div
            v-if="showLayout"
            class="hidden md:block"
            style="width: 5.2rem;"
          />
          <SiteSidebar
            v-if="showLayout"
            :open-side-menu="openSideMenu"
            class="hidden md:block fixed top-0 left-0   "
          />

          <div
            v-if="showLayout"
            class="bg-white border-r shadow-inner transition-all duration-500 md:hidden"
            :style="openSideMenu ? 'width: 75vw; max-width: 25rem' : 'width: 0'"
            style="margin-left: -1px;"
          >
            <mobileSidebar
              v-if="showLayout && showContent && enableSidebar"
              v-click-outside="closeSideMenu"
              class="fixed top-0 left-0"
              style="width: 75vw; max-width: 25rem"
              @open="open"
            />
          </div>

          <div
            class="relative h-full mobileWidth min-h-screen pattern w-screen md:w-full overflow-y-hidden"
            :class="showLayout ? 'md:pb-0' : ''"
            :style="showLayout && $isMobile.value ? 'padding-bottom:60px' : ''"
            @click="checkMenuState"
          >
            <div v-if="!openWindow">
              <site-header
                v-if="showLayout || (showLayout && !isAuthenticated)"
                :open-side-menu="openSideMenu"
                class="block md:hidden"
              />
              <DesktopHeader
                v-if="showLayout && !$isMobile.value"
                class="hidden md:block"
                @startTour="startTour"
              />
            </div>
            <div
              v-else
              class="fixed top-0 left-0 z-30 w-full bg-white border-b h-16 flex items-center justify-start pl-6"
              @click="closeWindow()"
            >
              <div class="leading-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="chevron-left"
                  class="w-5 h-5 text-gray-600"
                  role="img"
                  viewBox="0 0 320 512"
                ><path
                  fill="currentColor"
                  d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                /></svg>
              </div>
              <div
                class="text-base ml-6 leading-none text-gray-600"
                style="margin-bottom: 1px;"
              >
                {{ windowView }}
              </div>
            </div>

            <!-- <div
              class="w-full"
              :class="$isMobile.value && windowView !== '' ? 'h-0 sm:h-28' : 'h-0'"
            /> -->

            <div
              v-if="IsImpersonating"
              class="p-2 bg-gradient-to-b from-red-500 to-red-700 text-white text-center brand-heading"
            >
              You are currently impersonating {{ username }}
            </div>

            <div
              v-if="!$isMobile.value && isAuthenticated && IsExpired && completedFirstPayment && !isExperienceMember"
              :class="!IsImpersonating ? 'mt-16 md:mt-28' : ''"
              style="margin-left: 3px;"
            >
              <information-box
                :message="canChangeHomeClub ? 'Renew now, and choose from any of our ' + numClubs + ' clubs as your home club' : ''"
                title="Membership Expired"
                severity="warning"
                link-message="Renew"
                link-url="/membership/renew"
              />
            </div>

            <router-view
              class="w-full min-w-full h-full min-h-screen  overflow-y-hidden md:pb-24 md:-mb-24"
              :class="routerViewClasses()"
            />
            <site-footer v-if="showLayout && !hideFooter" />
          </div>
          <MobileMenuBar
            v-if="showLayout && isAuthenticated && $isMobile.value"
            id="bottomMobileBar"
            class="fixed left-0 bottom-0 z-30 w-full min-w-full md:hidden transition-all duration-500 spacing shadow"
            :style="openSideMenu ? 'margin-left: 75vw; max-margin' : ''"
            @resetWindow="resetWindow()"
          />
        </div>
      </div>
    </transition>

    <v-offline @detected-condition="handleConnectivityChange" />

    <v-tour
      name="welcomeTour"
      :steps="steps"
      :callbacks="myCallbacks"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { nodeEnv } from '@/environment/environment';
import VOffline from 'v-offline';
import InformationBox from '@/app/shared/components/base/informational/informationBox.vue';
import SiteFooter from '@/app/shared/components/layout/siteFooter/siteFooter.vue';
import { services } from './shared/services';
import siteHeader from './shared/components/layout/siteHeader/siteHeader.vue';
import MobileMenuBar from './shared/components/layout/mobileMenuBar/mobileMenuBar.vue';
import mobileSidebar from './shared/components/layout/mobileSidebar/mobileSidebar.vue';
import router from './app-routes';
import '../assets/site.scss';
import '../assets/clubBranding.scss';
import '../assets/settings.scss';
import SiteSidebar from './shared/components/layout/siteSidebar/siteSidebar.vue';
import DesktopHeader from './shared/components/layout/siteHeader/desktopHeader.vue';

export default {
  name: 'App',

  components: {
    siteHeader,
    MobileMenuBar,
    VOffline,
    mobileSidebar,
    SiteSidebar,
    DesktopHeader,
    InformationBox,
    SiteFooter,
  },

  data() {
    return {
      loading: true,
      environment: undefined,
      online: true,
      loadedFonts: [],
      cookieClose: undefined,
      openWindow: false,
      previousPath: '',
      windowView: '',
      enableSidebar: false,
      globalLoadingSpinnerTime: 0,
      numClubs: 260,

      myCallbacks: {
        onFinish: this.tourFinished,
        onSkip: this.tourFinished,
        onStop: this.tourFinished,
      },
    };
  },

  computed: {
    steps() {
      return [
        {
          target: '#v-step-0',
          header: {
            title: 'Getting Started',
          },
          content: 'Welcome to the new PlayMoreGolf Members Area! The same great membership but with a new lick of paint. Not quite sure where something is? Follow our guide to help you get familiar with the site.',
          params: {
            placement: 'top-end',
          },
        },
        {
          target: '#myProfileSidebar',
          header: {
            title: 'Your Profile',
          },
          content: 'This is where you can find your basic membership information, such as expiry date and remaining points.',
          params: {
            enableScrolling: false,
            placement: 'left-start',
          },
        },
        {
          target: '#myBookings',
          header: {
            title: 'Your Bookings',
          },
          content: 'View and manage your upcoming bookings in this tab.',
          params: {
            enableScrolling: false,
            placement: 'top',
          },
          before: () => new Promise((resolve) => {
            if (this.$route.fullPath !== '/' && this.$route.fullPath !== '/bookings') {
              this.$router.push('/bookings').then(() => {
                resolve('');
              });
            } else {
              resolve('');
            }
          }),
        },
        {
          target: '#exploreMenuItem',
          header: {
            title: 'Booking a Round',
          },
          content: `Don't have an upcoming booking yet? Explore the ${this.numClubs}+ clubs in the PMG network here.`,
          params: {
            enableScrolling: false,
            placement: 'bottom',
          },
        },
        {
          target: '#buddiesMenuItem',
          header: {
            title: 'Playing with Buddies',
          },
          content: 'Golf is better with friends. Don\'t forget to add the buddies you would like to play with.',
          params: {
            enableScrolling: false,
            placement: 'bottom',
          },
        },
        {
          target: '#purchasesMenuItem',
          header: {
            title: 'Additional Points',
          },
          content: 'You can top-up your points here, or you can renew early if your expiry date is near.',
          params: {
            enableScrolling: false,
            placement: 'bottom',
          },
        },
        {
          target: '#helpSupport',
          header: {
            title: 'Have a question?',
          },
          content: 'You\'re likely to find the answer in our help centre, or you contact our customer service if you\'d prefer to speak to someone.',
          params: {
            enableScrolling: false,
            placement: 'top',
          },
        },
      ];
    },

    theme() {
      return this.$store.getters.getCurrentTheme;
    },

    hideFooter() {
      return this.$route.meta.hideFooter === true;
    },

    memberSummary() {
      return this.$store.getters.getMemberSummary;
    },

    showLayout() {
      return this.$store.state.theme.showLayout && !this.$store.state.theme.squeezePage && (this.$isStandard || this.isAuthenticated);
    },

    hideGlobalLoader() {
      return this.$store.state.theme.hideGlobalLoader;
    },

    hideHomeClub() {
      return this.$store.state.theme.hideHomeClub;
    },

    ...mapState({
      username: state => state.user.username,
      IsExpired: state => state.user.IsExpired,
      isAuthenticated: state => state.user.isAuthenticated,
      homeClubId: state => state.user.homeClubId,
      homeClubName: state => state.user.homeClubName,
      completedFirstPayment: state => state.user.completedFirstPayment,
      clubLoaded: state => state.club.clubLoaded,
      openSideMenu: state => state.layout.openSideMenu,
      textSize: state => state.settings.textSize,
      IsImpersonating: state => state.user.IsImpersonating,
      refreshingMemberSummaryError: state => state.user.refreshingMemberSummaryError,
      canChangeHomeClub: state => state.user.canChangeHomeClub,
      hasCompletedTour: state => state.state.hasCompletedTour,
    }),

    ...mapGetters({
      isExperienceMember: 'isExperienceMember',
    }),

    showLoadingSpinner() {
      if (this.username === '' && !this.hideGlobalLoader && this.online) {
        return true;
      }
      return false;
    },

    showContent() {
      if (this.username !== '' || this.hideGlobalLoader || !this.online) {
        return true;
      }
      return false;
    },

    locale() {
      return this.$store.state.user.locale;
    },

  },

  watch: {
    showLoadingSpinner() {
      if (this.showLoadingSpinner) {
        this.timeLoadingSpinner();
      }
    },

    refreshingMemberSummaryError() {
      if (this.refreshingMemberSummaryError) {
        location.reload();
      }
    },

    online() {
      if (nodeEnv === 'cordova') {
        if (this.online) {
          if (router.currentRoute.name === 'offline') {
            if (this.isAuthenticated) {
              router.push('/');
            } else {
              router.push('/Login');
            }
          }
        } else {
          router.push('/error/offline');
        }
      }
    },

    openSideMenu() {
      if (!this.enableSidebar) {
        this.enableSidebar = true;
      }
    },

    locale: {
      immediate: true,
      handler(newVal) {
        this.setMomentLocale(newVal);
      },
    },

    $route(to, from) {
      if (this.$isMobile.value) {
        if (from.meta.isWindowView) {
          this.openWindow = false;
          this.windowView = '';
        }

        if (to.meta.isWindowView) {
          this.openWindow = true;
          this.$store.dispatch('setSideMenuState', false);
          this.windowView = to.meta.windowViewTitle;
        }
      }

      if (this.$tours.welcomeTour.currentStep === 2) {
        if (this.$route.fullPath !== '' && this.$route.fullPath !== '/' && this.$route.fullPath !== '/bookings' && this.$route.fullPath !== '/referrals') {
          this.$tours.welcomeTour.stop();
        }
      }
    },
  },

  created() {
    this.environment = nodeEnv;
    this.setMomentLocale(this.locale);
    this.injectAssets();
    // this.$store.commit('setOverridingClubId', this.homeClubId);
    // Probably can do this in the user store rather than callig cookieAuth twice
    services.account.cookieAuthenticated().then((result) => {
      const authState = this.isAuthenticated;
      this.$store.dispatch('setAuth', result);
      if (authState && !result) {
        location.reload();
      }
      this.$store.dispatch('updateStates');
      this.$store.dispatch('populateMemberSummary');
      if (result) {
        this.$store.dispatch('refreshBasket');
      }
    });
  },

  mounted() {
    this.timeLoadingSpinner();
    this.initConnectitvityCheck();

    services.club.getNetworkStats().then((data) => {
      this.numClubs = data.NumClubs;
    });

    window.onresize = () => {
      this.$isMobile.value = process.env.VUE_APP_ENV === 'cordova' || window.innerWidth < 924;
    };
  },

  methods: {
    startTour() {
      if (this.$isStandard) {
        if (!this.$isMobile.value && !this.hasCompletedTour && this.completedFirstPayment) {
          setTimeout(() => this.$tours.welcomeTour.start(), 1000);
        } else if (this.username === '') {
          setTimeout(() => this.startTour(), 1000);
        }
      }
    },

    tourFinished() {
      this.$store.dispatch('updateTutorialState', true);
    },

    timeLoadingSpinner() {
      if (this.showLoadingSpinner) {
        this.globalLoadingSpinnerTime = (this.globalLoadingSpinnerTime + 1000);
        if (this.globalLoadingSpinnerTime > 11000) {
          location.reload();
        }
        setTimeout(() => this.timeLoadingSpinner(), 1000);
      }
    },

    routerViewClasses() {
      let classes = '';
      if (this.showLayout) {
        classes += 'routerview ';

        // so tihs is the padding that accounts for the top menu
        // Disgusting, bleh
      }

      if (this.showLayout && !this.hideFooter) {
        classes += ' pb-36 -mb-36 ';
      }

      // Disgusting
      if ((!this.IsImpersonating && !this.IsExpired && this.completedFirstPayment) || this.$isMobile.value || !this.isAuthenticated || !this.completedFirstPayment) {
        classes += 'md:mt-18';
      }

      return classes;
    },
    sideMenuClick() {
      this.$store.dispatch('switchSideMenuState');

      if (this.firstProfileOpen) {
        localStorage.setItem('firstProfileOpen', JSON.stringify(true));
        this.firstProfileOpen = false;
      }
    },
    initConnectitvityCheck() {
      document.addEventListener('offline', this.handleConnectivityChange, false);
      document.addEventListener('deviceready', this.handleConnectivityChange, false);
      document.addEventListener('resume', this.handleConnectivityChange, false);
      this.handleConnectivityChange();
    },

    handleConnectivityChange() {
      if (this.$isMobileApp) {
        this.online = navigator.connection.type !== 'none';
      }
    },

    getClasses() {
      if (this.showLayout) {
        if (this.$isStandard) {
          return `playmoregolf text${this.textSize} md:mt-16`;
        }
        return `nine text${this.textSize} md:mt-16`;
      }
      if (this.$isStandard) {
        return `playmoregolf text${this.textSize}`;
      }
      return `nine text${this.textSize}`;
    },

    checkMenuState() {
      if (this.openSideMenu && !this.openWindow) {
        this.$store.dispatch('setSideMenuState', false);
      }
    },

    resetWindow() {
      this.openWindow = false;
      this.$store.dispatch('setSideMenuState', false);
    },

    closeWindow() {
      this.openWindow = false;
      this.$router.push(this.previousPath === '' ? '/' : this.previousPath);
    },

    open(window) {
      this.previousPath = this.$router.history.current.path;
      this.$router.push(window.path);
      this.closeSideMenu();
    },


    closeSideMenu() {
      if (this.openSideMenu) {
        document.addEventListener('click', this.tempClickDisable, true);
        setTimeout(() => this.reenableClick(), 100);
        this.$store.dispatch('setSideMenuState', false);
      }
    },

    tempClickDisable(e) {
      e.stopPropagation();
      e.preventDefault();
    },

    reenableClick() {
      document.removeEventListener('click', this.tempClickDisable, true);
    },

    setMomentLocale(locale) {
      this.$moment.locale(locale);
    },

    injectAssets() {
      // Horrible
      const baseUrl = process.env.VUE_APP_BASE_URL;
      const indexJs = document.createElement('script');
      indexJs.setAttribute('type', 'text/javascript');
      indexJs.setAttribute('src', `${baseUrl}js/index.js`);
      document.head.appendChild(indexJs);

      const siteJs = document.createElement('script');
      siteJs.setAttribute('type', 'text/javascript');
      siteJs.setAttribute('src', `${baseUrl}js/site.js`);
      document.head.appendChild(siteJs);

      const siteCss = document.createElement('link');
      siteCss.setAttribute('rel', 'stylesheet');
      siteCss.setAttribute('type', 'text/css');
      siteCss.setAttribute('href', `${baseUrl}css/site.css`);
      document.head.appendChild(siteCss);

      const patternCss = document.createElement('link');
      patternCss.setAttribute('rel', 'stylesheet');
      patternCss.setAttribute('type', 'text/css');
      patternCss.setAttribute('href', `${baseUrl}css/pattern.min.css`);
      document.head.appendChild(patternCss);

      // const tooltipCss = document.createElement('link');
      // tooltipCss.setAttribute('rel', 'stylesheet');
      // tooltipCss.setAttribute('type', 'text/css');
      // tooltipCss.setAttribute('href', `${baseUrl}css/css-tooltip.css`);
      // document.head.appendChild(tooltipCss);

      if (this.$isMobileApp) {
        const cordovaJs = document.createElement('script');
        cordovaJs.setAttribute('type', 'text/javascript');
        cordovaJs.setAttribute('src', 'cordova.js');
        document.head.appendChild(cordovaJs);
      }
    },
  },

};
</script>

<style src="../assets/site.css"></style>

<style lang="scss">
.v-step {
  z-index: 40 !important;
  background: #ffffff !important;
  color: rgb(75, 85, 99) !important;
  transition: opacity;
  transition-duration: 250ms;
  box-shadow: 0px 0px 45px 5px !important;
  //text-align: left !important;
}

.v-step__content {
  font-family: var(--body-font) !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.v-step__header {
  font-family: var(--heading-font);
  background: linear-gradient(rgb(255, 255, 255), rgb(240, 240, 240)) !important;
  color: rgb(75, 85, 99) !important;
  //text-align: left !important;
  //padding-left: 0.9rem !important;
}

.v-step__buttons {
  //text-align: right !important;
}

.v-step__button {
    font-family: var(--button-secondary-font) !important;
    font-size: var(--button-secondary-size) !important;
    text-transform:  var(--button-secondary-transform) !important;
    background-color: var(--button-secondary-background) !important;
    border-color: var(--button-secondary-border-color) !important;
    color: #002b44b7 !important;
    text-transform: var(--button-secondary-transform) !important;
    cursor: pointer;
    text-align: center;
    transition: all;
    transition-duration: 500ms;
    font-weight: 500 !important;
    border-radius: 0.5rem !important;
    transition: all;
    transition-duration: 250ms;
}

.v-step__button:hover {
  background-color: var(--button-secondary-background-hover) !important;
  color: var(--button-secondary-color-hover) !important;
}

.v-step__arrow--dark:before {
    background: #ffffff !important;
}
</style>

<style lang="scss">
.fa-secondary{opacity:.4}
 @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    src: url('#{$URL}fonts/Inter-Regular.woff2');
  }

  @font-face {
    font-family: 'Poppins';
    src: url('#{$URL}fonts/Poppins-Medium.ttf') format('truetype');
  }

</style>

<style>
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.5s !important;
    transition-property: opacity !important;
    transition-timing-function: ease !important;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0 !important;
  }

@media (max-width: 924px) {
  .routerview {
    margin-top: 4rem/* 64px */;
  }
}

</style>
