import Vue from 'vue';
import Router from 'vue-router';
import { nodeEnv } from '@/environment/environment';

// eslint-disable-next-line import/no-cycle
import { store } from '@/app/shared/stores/store';
import { homeRoutes } from './home';
import { bookingRoutes } from './booking';
import { helpRoutes } from './help';
import { clubRoutes } from './club';
import { accountRoutes } from './account';
import { memberRoutes } from './member';
import { checkoutRoutes } from './checkout';
import { renewRoutes } from './renew';
import { signupRoutes } from './signup';
import { exploreRoutes } from './explore';
import { errorRoutes } from './error';
import { subscriptionRoutes } from './subscription';
import { infoAndUpdatesRoutes } from './infoAndUpdates';
import { supportRoutes } from './support';
import { settingRoutes } from './settings';
import { giftCardRoutes } from './giftCards';
import { policyRoutes } from './policies';

Vue.use(Router);

const appRoutes = [
  {
    path: '*',
    name: 'pageNotFound',
    component: () => import('./shared/components/error-pages/page-not-found.vue'),
    meta: {
      title: '404 - Page not found',
    },
  },
];

const routes = [...accountRoutes, ...appRoutes, ...bookingRoutes, ...subscriptionRoutes,
  ...supportRoutes, ...clubRoutes, ...memberRoutes, ...checkoutRoutes, ...helpRoutes, ...errorRoutes, ...signupRoutes, ...homeRoutes, ...exploreRoutes,
  ...infoAndUpdatesRoutes, ...renewRoutes, ...settingRoutes, ...giftCardRoutes, ...policyRoutes];

const router = new Router({
  mode: nodeEnv === 'cordova' ? '' : 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.meta !== undefined && from.meta !== undefined) {
      if (to.meta.subMenu && from.meta.subMenu) {
        // it's -2 because one for length to array and another one because we want parent to check it's the same submenu
        if (to.matched[to.matched.length - 2].path === from.matched[to.matched.length - 2].path) {
          const scrollAuto = {
            behavior: 'auto',
            alignToTop: true,
          };
          const elem = document.getElementById('subMenu');
          if (elem !== undefined && elem !== null) {
            elem.scrollIntoView(scrollAuto);
          }
        }
        return null;
      }
    }
    if (savedPosition) {
      return savedPosition;
    }
    if (to.meta.noAutoScroll) {
      return null;
    }
    return { x: 0, y: 0 };
  },
});

// authentication check
router.beforeEach(async (to, from, next) => {
  if (to.meta.title !== undefined) {
    document.title = to.meta.title;
  }

  if (to.matched.some(record => record.meta.noLayout) || (to.matched.some(record => record.meta.noLayoutMobile) && (process.env.VUE_APP_ENV === 'cordova' || window.innerWidth < 924))) {
    store.commit('toggleLayout', false);
  } else {
    store.commit('toggleLayout', true);
  }

  if (to.matched.some(record => record.meta.squeezePage)) {
    store.commit('toggleSqueezePage', true);
  } else {
    store.commit('toggleSqueezePage', false);
  }

  if (to.matched.some(record => record.meta.hideHomeClub)) {
    store.commit('toggleHideHomeClub', true);
  } else {
    store.commit('toggleHideHomeClub', false);
  }

  if (to.matched.some(record => record.meta.overrideStyle)) {
    store.commit('setOverrideStyle', true);
  } else {
    store.commit('setOverrideStyle', false);
  }

  // todo: yuck, fix
  if (store.state.user.isAuthenticated) {
    store.commit('toggleHideGlobalLoader', false);
    next(); // continue

    // Disable for now because you can't access login page in restrictive mode anyways
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    store.commit('toggleHideGlobalLoader', true);
    store.dispatch('setAuth', false);

    if (router.currentRoute.name === 'login'
      && router.currentRoute.name === 'loginForClub'
      && router.currentRoute.name === 'loginBad') {
      // do nothing
    } else {
      const returnUrl = `${window.location.pathname}${window.location.search}`;
      if (returnUrl !== '/' || returnUrl.includes('membership')) {
        router.push({ name: 'login', query: { returnUrl } });
      } else {
        router.push({ name: 'login' });
      }
    }
  } else {
    store.commit('toggleHideGlobalLoader', true);
    next(); // not authed, no authentication needed
  }
});

export default router;
