/* eslint-disable no-param-reassign */
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';

import Vue from 'vue';
import VueOffline from 'vue-offline';
import VueBbob from '@bbob/vue2';
import VueTour from 'vue-tour';

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import { store } from '@/app/shared/stores/store';
import i18n from '@/i18n';
import router from '@/app/app-routes';
import App from '@/app/app.vue';
import moment from 'moment';
import Vue2TouchEvents from 'vue2-touch-events';
import VueCompositionAPI from '@vue/composition-api';
import VueGtag from 'vue-gtag';

require('vue-tour/dist/vue-tour.css');

Vue.config.productionTip = false;
Vue.prototype.$moment = moment;

Vue.use(VueCompositionAPI);
Vue.use(Datetime);
Vue.use(Vue2TouchEvents);
Vue.use(VueTour);

// pretty sure where it was before it was being applied globally anyway
Vue.use(VueBbob);

// To-do: no need to inject into every component
// https://www.npmjs.com/package/vue-offline
Vue.use(VueOffline);

Vue.use(VueGtag, {
  config: { id: 'G-HP9TEYB9NB' },
});

// if (true || process.env.NODE_ENV === 'production' && process.env.VUE_APP_ENV !== 'cordova') {
//   Analytics({
//     app: 'MembersWebVue',
//     plugins: [
//       googleTagManager({
//         containerId: 'GTM-MNTLS9S',
//       }),
//     ],
//   });
// }

Sentry.init({
  Vue,
  dsn: 'https://1265d2005dda49dc90069f385d063cca@o1203955.ingest.sentry.io/6330579',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['playmore.golf', 'account.playmore.golf', /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    el.eventSetDrag = () => {
      el.setAttribute('data-dragging', 'yes');
    };
    el.eventClearDrag = () => {
      el.removeAttribute('data-dragging');
    };
    el.eventOnClick = (event) => {
      const dragging = el.getAttribute('data-dragging');
      // Check that the click was outside the el and its children, and wasn't a drag
      if (!(el === event.target || el.contains(event.target)) && !dragging) {
        // call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.addEventListener('touchstart', el.eventClearDrag);
    document.addEventListener('touchmove', el.eventSetDrag);
    document.addEventListener('click', el.eventOnClick);
    document.addEventListener('touchend', el.eventOnClick);
  },
  unbind(el) {
    document.removeEventListener('touchstart', el.eventClearDrag);
    document.removeEventListener('touchmove', el.eventSetDrag);
    document.removeEventListener('click', el.eventOnClick);
    document.removeEventListener('touchend', el.eventOnClick);
    el.removeAttribute('data-dragging');
  },
});

Vue.directive('local-img', {
  // When the bound element is inserted into the DOM...
  inserted: (el, binding) => {
    if (binding.value.includes('https://')) {
      el.src = binding;
    } else {
      el.src = `${process.env.VUE_APP_BASE_URL}img/${binding.value}`;
    }
  },
});

Vue.directive('local-img-bg', {
  // When the bound element is inserted into the DOM...
  inserted: (el, binding) => {
    const backgroundText = binding.value.match(/(?=url\().*?(?=\))/);
    backgroundText[0] = backgroundText[0].replace('"', '');
    backgroundText[0] = backgroundText[0].replace('url(', '');
    const backgroundTextToUse = binding.value.replace(backgroundText[0], `"${process.env.VUE_APP_BASE_URL}img/${backgroundText[0]}"`);
    const cssToUse = `background-image: ${backgroundTextToUse}`;
    el.style.cssText += cssToUse;
  },
});

Vue.prototype.$isProduction = process.env.VUE_APP_ENV === 'production';

Vue.prototype.$isStandard = process.env.VUE_APP_PMG_ENV === 'Standard';
Vue.prototype.$isNine = process.env.VUE_APP_PMG_ENV === 'IX';

Vue.prototype.$isMobileApp = process.env.VUE_APP_ENV === 'cordova';
Vue.prototype.$isMobile = Vue.observable({
  value: process.env.VUE_APP_ENV === 'cordova' || window.innerWidth < 924,
});

Vue.prototype.$isIOS = process.env.VUE_APP_ENV === 'cordova' && process.env.VUE_APP_CORDOVA_PLATFORM === 'ios';
Vue.prototype.$isAndroid = process.env.VUE_APP_ENV === 'cordova' && process.env.VUE_APP_CORDOVA_PLATFORM === 'android';

// maybe wrong but easy and feels safe
store.$isNine = Vue.prototype.$isNine;
store.$isStandard = Vue.prototype.$isStandard;
store.$isIOS = Vue.prototype.$isIOS;
store.$isMobileApp = Vue.prototype.$isMobileApp;

const vue = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
});

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/sw.js').then((registration) => {
//       console.log('SW registered: ', registration);
//     }).catch((registrationError) => {
//       console.log('SW registration failed: ', registrationError);
//     });
//   });
// }

vue.$mount('#app');
