const exploreRoutes = [
  {
    path: '/explore/points-matrix',
    name: 'ExploreMatrix',
    component: () => import('./exploreMatrices/exploreMatrices.vue'),
    meta: {
      title: 'Points Matrix',
      requiresAuth: false,
    },
  },
];

export default exploreRoutes;
