import { services } from '../services';

const packagesStore = {
  state: {
    packages: undefined,
    lastFetch: undefined,
  },

  mutations: {
    updatePackages(state, packages) {
      state.packages = packages;
      state.lastFetch = new Date();
    },
    emptyPackagesCache(state) {
      state.packages = undefined;
      state.lastFetch = undefined;
    },
  },

  actions: {
    async updatePackages(context) {
      const packages = await services.checkout.availablePackages();
      context.commit('updatePackages', packages);
    },
    async emptyPackages({ commit }) {
      commit('emptyPackagesCache');
    },
  },

};

export { packagesStore };
