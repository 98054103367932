const enMessages = {
  en: {
    booking: {
      again: 'Book Again',
      bookedBy: 'Booked by',
      booking: 'booking | bookings',
      bookingHistory: 'Booking History',
      cancel: 'Cancel Booking',
      cancelled: 'Cancelled',
      course: 'Course | Courses',
      details: 'Booking Details',
      junior: 'Junior | Juniors',
      loadingHistory: 'Loading your booking history',
      player: 'Player | Players',
      success: 'Your booking has been succesful!',
    },
    buddies: {
      buddyMenu: {
        buddies: 'Buddies',
        pending: 'Pending',
        requests: 'Requests',
      },
      management: {
        acceptDialogue: {
          header: 'Accept Buddy',
          list1: 'Add you as a player (Buddy) to a booking, using your points, without confirmation',
          list2: 'Know if you have insufficient points to make a booking, and have the option to use their points instead',
          list3: 'and you will be able to do the same',
          listEnd: 'Additionally, any player (Buddy) added to a booking will have the option to cancel the booking (subject to normal cancellation terms) which will cancel and refund the entire booking.',
          listHeader: 'This will mean {0} will be able to:',
          listWarning: 'You shouldn\'t accept buddy invitations from people you don\'t know. You can revoke Buddy priviledges at any time.',
          message: 'Are you sure you want to accept this Buddy invitation from {0}?',
        },
        cancelRequestDialogue: {
          header: 'Cancel Buddy Request',
          message: 'Are you sure you want to cancel your buddy request to {0}?',
        },
        declineDialogue: {
          header: 'Decline Buddy Request',
          message: 'Are you sure you want to decline your buddy request from {0}?',
        },
        findDialogue: {
          buddyFound: 'Buddy Found',
          couldntFind: 'Sorry, we couldn\'t find anyone using that share code or email address.',
          findBuddy: 'Add Buddy',
          header: 'Add a Buddy',
          list1: '@:buddies.management.acceptDialogue.list1',
          list2: '@:buddies.management.acceptDialogue.list2',
          list3: '@:buddies.management.acceptDialogue.list3',
          listEnd: '@:buddies.management.acceptDialogue.listEnd',
          listHeader: 'We\'ve found a match. Are you sure you want to send {fullName} a Buddy Request? {firstName} will need to confirm this request. Once confirmed, {firstName} will be able to:',
          message: 'To add a buddy you\'ll need their email address or share code (If you don\'t know it, ask your friend to send it to you).',
          placeholder: 'Buddy share code or email address',
        },
        removeDialogue: {
          header: 'Remove Buddy',
          message: 'Are you sure you want to unbuddy {0}?',
        },
        unbuddy: 'Unbuddy',
      },
      shareCode: {
        copy: 'Copy buddy code',
        label: 'Your buddy share code',
        title: 'What is a share code?',
        subHeading: 'A share code helps your buddy find you.',
        p1: 'You can give this sharing code to your friends to make it easy for them to send a buddy request to you.',
        p2: 'Ask them to use this code in the "Add Buddy" section on their Buddy Management page.',
        p3: 'Even with the Share Code, a friend\'s buddy request needs to be confirmed by you before you\'ll become Buddies.',
        p4: 'For security and privacy reasons we recommend you use your unique Share Code instead of sharing your email address.',
      },
    },
    club: {
      club: 'Club | Clubs',
    },
    commonUi: {
      accept: 'Accept',
      back: 'Back',
      backToLogin: 'Back to Login',
      cancel: 'Cancel',
      close: 'Close',
      confirm: 'Confirm',
      date: 'Date | Dates',
      decline: 'Decline',
      default: 'Default',
      empty: 'Empty',
      emailAddress: 'Email Address',
      flexi: 'Flexi',
      forgotPassword: 'Reset Password',
      home: 'Home',
      login: 'Login',
      loggedInAs: 'Logged in as',
      logout: 'Logout',
      newer: 'Newer',
      no: 'No',
      note: 'Note | Notes',
      older: 'Older',
      overview: 'Overview',
      password: 'Password',
      point: 'Point | Points',
      resetPassword: 'Reset Password',
      remove: 'Remove',
      search: 'Search',
      sortBy: 'Sort by',
      yes: 'Yes',
    },
    explore: {
      clubList: {
        miles: 'miles',
        sortBy: {
          aZ: 'A-Z',
          bestMatch: 'Best match',
          distance: 'Distance',
        },
      },
      teeTimes: {
        address: 'Address',
        afternoonTeeTimes: 'Afternoon @:explore.teeTimes.teeTimes',
        backToExplore: 'Back to Explore',
        backToFilters: 'Back to Search Filters',
        eveningTeeTimes: 'Evening @:explore.teeTimes.teeTimes',
        morningTeeTimes: 'Morning @:explore.teeTimes.teeTimes',
        nextDay: 'Next day',
        noPlayerSlotsAvailable: 'No player slots remaining for',
        noPlayerSlotsAvailableHelp: 'Sorry, you no longer have any player slots availble for the selected date. Please try another.',
        noTeeTimesAvailable: 'No tee times available for',
        noTeeTimesAvailableHelp: 'Sorry, we couldn\'t find any available tee times for the selected date. Please try another.',
        pointsMatrix: 'Points Matrix',
        previousDay: 'Previous day',
        price: 'Price',
        teeTimes: 'Tee times',
        time: 'Time',
        website: 'Website',
      },
    },
    home: {
      flexi: '@.lower:commonUi.flexi',
      home: '@.lower:commonUi.home',
      homeMenu: {
        dashboard: 'Dashboard',
        bookings: 'Bookings',
        referrals: 'Referrals',
        renew: 'Renew',
        statistics: 'Statistics',
        topUp: 'Top-up',
      },
      renew: {
        benefit1: {
          body: 'Use your flexi points to play at the many golf clubs in the network.',
          title: 'Play at different clubs',
        },
        benefit2: {
          body: 'Use your flexi points to play at the many golf clubs in the network.',
          title: 'Book via our website or app',
        },
        benefit3: {
          body: 'Golf is better with friends, continue to bring along guests or other members.',
          title: 'Bring up to 3 guests',
        },
        benefit4: {
          body: 'Continuing your flexible membership means that you can keep your handicap.',
          title: 'Keep your handicap',
        },
        buttonHelp: 'Renewing now will add another year to your current renewal date.',
        buttonText: 'Renew now',
        title: 'Extend your membership for another 12 months. Support your club, maintain your handicap, and play at any club in the network.',
      },
      topUp: {
        flexi: {
          body: 'Additional Flexi Points can be spent on rounds of golf at any club in the PlayMoreGolf network, including your Home Club. They are valid for 12 months from purchase.',
          info: 'This package will not extend your renewal date.',
          subtitle: 'More points to play at home or away',
          title: 'Flexi  points',
        },
        home: {
          body: 'Additional Home Points can be spent on rounds of golf at your home club. Any Home Points remaining can be carried over to next year\'s membership, at renewal.',
          info: 'This package will not extend your renewal date.',
          subtitle: 'More points to play at home',
          title: 'Home points',
        },
        loading: 'Loading top-up packages',
      },
    },
    login: {
      noAccount: 'Don\'t have an account?',
      incorrectDetails: 'Sorry, your email address or password is incorrect',
      passwordResetInfo: 'We\'ll send a password reset link to your email address',
      passwordReset: 'If the email address {0} exists in our system, we\'ve sent you instructions to reset your password.',
      resetPasswordMessageClubSpecific: 'Reset Your Password for {0}',
      signInMessage: 'Sign in to your account',
      signInMessageClubSpecific: 'Sign in to {0}',
      signUp: 'Sign up',
    },
    register: {
      confirmPassword: 'Confirm password',
      createMyAccount: 'Create my account',
      firstName: 'First name',
      failed: 'Sorry, registration failed. Please ensure your details are valid.',
      phoneNumber: 'Phone number',
      pleaseSignIn: 'Please sign in',
      registerAnAccount: 'Register an Account',
      subtitle: 'We need to know some details about your to create an account. You\'ll use these details to book online and manage your points.',
      subtitleCorporate: 'We need to know some details about your company to create you an account. You\'ll use these details to book online and manage your points.',
      surname: 'Surname',
      referred: 'Referred by a friend?',
      referalInstruction: 'Enter their e-mail address',
      referalEmail: 'Referal email',
      validation: {
        emailAddress: 'Valid e-mail address required',
        emailExists: 'E-mail address already exists. Please use a different one.',
        firstName: 'First name required',
        surname: 'Surname required',
        phoneNumber: 'Valid phone number required',
        password: 'Valid password required',
        passwordsDoNotMatch: 'Passwords do not match',
        passwordMinChars: 'Minimum of {0} characters required',
        usernameExists: 'Sorry, the email {0} is already attached to an account. If this is your account, please return to the login screen where you can login or reset your password.',
      },
    },
    logout: {
      areYouSure: 'Are you sure you want to logout?',
    },
    package: {
      flexibleMembership: 'Flexible Membership',
      instalmenPlanAvailable: 'Instalment plan available at checkout',
      renewYourMembership: 'Renew your membership',
      startYourMembership: 'Start your membership',
      title: 'Your Membership',
    },
    payment: {
      addToBasket: 'Add To Basket',
      amount: 'Amount',
      cards: {
        add: 'Add Card',
        addNew: 'Add a new card',
        card: 'Card | Cards',
        cvc: 'cvc',
        changePaymentCard: 'Change payment card',
        detailsSecure: 'Your details are secure',
        detailsSecureInfo: 'When you make a payment your card details are securely stored to make future payments easier, manage instalment plans and auto-renewals.',
        expiresAt: 'Expires at {0}',
        expiryDate: 'Expiry Date',
        invalid: 'Sorry, that looks like an invalid card. That\'s usually because the expiry date or CV2 code has been entered incorrectly.',
        learnMore: 'Learn more about saved cards',
        loading: 'Loading your cards',
        noneSaved: 'No cards saved',
        nameOn: 'Name on card',
        number: 'Card number',
        postcode: '@:userDetails.postcode',
        remove: 'Remove Card',
        removeDialogue: 'Are you sure you want to remove the card ending in {0} from your account?',
        saved: 'Saved Cards',
        setAsDefault: 'Set as Default',
        usingFollowingCard: 'using the following card:',
      },
      cost: 'Cost',
      price: 'Price | Prices',
      package: 'Package',
      subscription: {
        autoRenewingInstaller: 'This subscription will automatically renew on the {0}. If you don\'t want this to happen, you can Disable Auto Renew. If you change your mind you can re-enable it at a later date.',
        autoRenewingUnfinishedInstaller: 'This subscription will automatically renew on the {0}. You\'re currently part-way through an Instalment Plan, once this has completed you\'ll have the option to cancel Auto Renewal.',
        current: 'Current Subscription',
        disableAutoRenew: 'Disable Auto-renew',
        enableAutoRenew: 'Enable Auto-renew',
        inactive: 'No Active Subscription',
        inactiveMessage: 'You don\'t currently have an active subscription. By purchasing a new package, you\'ll be able to take advantage of the subscription management tools.',
        legacyInstaller: 'You have an ongoing Instalment Plan, this will automatically cancel when you\'ve completed your final payment.',
        legacyInstallerCancel: 'If you wish, you can cancel automatic renewals. Once cancelled, historical subscriptions cannot be re-enabled, so you\'ll need to make a new payment from the checkout.',
        loading: 'Loading your subscription',
        manage: 'Manage Subscription',
        noAutoRenew: 'This subscription will not automatically renew, to continue after expiry you\'ll have to make a payment manually. You can turn auto-renew back on, and we\'ll start taking payments again on {0}.',
        nextPayment: 'Next Payment',
        nextPaymentInfo: 'A payment of {currencySymbol}{amount} will be taken on the {date}',
        renewal: 'Renewal',
        renewing: 'Renewing',
        subscription: 'Subscription',
      },
      transactions: {
        loading: 'Loading your transactions',
        pointsHomeFlexi: 'Points (Home / Flexi)',
        transaction: 'Transaction | Transactions',
        type: {
          awayPointsBundle: 'Away Points Bundle',
          bookingAway: 'Flexi Booking',
          bookingHome: 'Home Booking',
          cancellation: 'Cancellation',
          customerImport: 'Customer Import',
          homePointsBundle: 'Home Points Bundle',
          newCustomer: 'New Customer',
          pointCredit: 'Point Credit',
          renewal: 'Renewal',
        },
      },
      type: 'Type',
      welcome: {
        subtitle: 'Thank you for your payment. It may take a few minutes for your points to appear.',
        title: 'Welcome to',
      },
      welcomeBack: {
        subtitle: 'Thank you for your payment. You\'ve successfully renewed your membership for another year, it\'s great to have you back!',
        title: 'Here\'s to another year of golf!',
      },
    },
    tabs: {
      account: 'Account',
      buddies: 'Buddies',
      explore: 'Explore',
      help: 'Help',
      home: 'Home',
      trophies: 'Trophies',
    },
    userDetails: {
      address1: 'Address 1',
      address2: 'Address 2',
      changePasswordForm: {
        change: 'Change Password',
        confirmNew: 'Confirm New Password',
        current: 'Current Password',
        failed: 'Sorry, we couldn\'t change your password, please ensure all fields are correct.',
        new: 'New Password',
        success: 'You\'ve successfully changed your password.',
      },
      contactDetails: 'Contact',
      contactReasoning: 'We use this info to keep in touch and find nearby clubs',
      dob: 'DoB',
      emailAddress: '@:commonUi.emailAddress',
      fullName: 'Full Name',
      handicap: 'Handicap',
      homeClub: 'Home Club',
      genderSelect: {
        female: 'Female',
        gender: 'Gender',
        male: 'Male',
        select: 'Select a Gender',
      },
      loading: 'Loading your account details',
      membershipExpires: 'Membership Expires',
      mobileNumber: 'Mobile Number',
      myDetails: 'My Details',
      otherPhone: 'Other Phone',
      personalInfo: 'Personal',
      postcode: 'Postcode',
      tellUsMore: 'Tell us a bit more about yourself',
      townCity: 'Town/City',
      updateDetails: 'Update Details',
      updating: 'Updating your account details',
    },
  },
};

export default enMessages;
