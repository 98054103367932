const subscriptionRoutes = [
  {
    path: '/subscription',
    component: () => import ('./subscription.vue'),
    name: 'Subscription',
    meta: {
      isWindowView: true,
    },
    children: [
      {
        path: '/subscription',
        component: () => import ('@/app/shared/components/account/manageSubscription.vue'),
        name: 'subscriptionSubscription',
        meta: {
          title: 'Subscriptions',
          isWindowView: true,
          windowViewTitle: 'Subscriptions',
        },
      },
      {
        path: '/cards',
        component: () => import ('@/app/shared/components/account/manageCards.vue'),
        name: 'subscriptionCards',
        meta: {
          title: 'Cards',
          isWindowView: true,
          windowViewTitle: 'Subscriptions',
        },
      },
    ],
  },
];

export default subscriptionRoutes;
