<template>
  <div ref="titleHack">
    <img
      v-if="existNow"
      v-local-img="sauce"
      :classes="classes"
    >
  </div>
</template>

<script>


export default {

  components: {
  },

  props: {
  },

  data() {
    return {
      existNow: false,
      sauce: '',
      classes: '',
    };
  },

  computed: {
  },

  mounted() {
    this.sauce = this.$refs.titleHack.title;
    this.classes = this.$refs.titleHack.classList.toString();
    this.existNow = true;
  },

  methods: {
  },

};
</script>
