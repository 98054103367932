<template>
  <div
    v-if="hasMessage"
    class="p-3 border-l-4 rounded-lg rounded-l-none text-left"
    :class="{ 'bg-blue-50 border-blue-700 p-4': isInformative,
              'bg-yellow-50 border-yellow-400 p-4': isWarning}"
  >
    <div class="flex">
      <div class="flex-shrink-0 mt-1">
        <svg
          v-if="isInformative"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false"
          data-prefix="fad"
          class="w-5 h-5 text-blue-700"
          role="img"
          viewBox="0 0 512 512"
        ><g class="fa-group"><path
          class="fa-secondary"
          fill="currentColor"
          d="M256 8C119 8 8 119.08 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 110a42 42 0 1 1-42 42 42 42 0 0 1 42-42zm56 254a12 12 0 0 1-12 12h-88a12 12 0 0 1-12-12v-24a12 12 0 0 1 12-12h12v-64h-12a12 12 0 0 1-12-12v-24a12 12 0 0 1 12-12h64a12 12 0 0 1 12 12v100h12a12 12 0 0 1 12 12z"
          opacity="0.4"
        /><path
          class="fa-primary"
          fill="currentColor"
          d="M256 202a42 42 0 1 0-42-42 42 42 0 0 0 42 42zm44 134h-12V236a12 12 0 0 0-12-12h-64a12 12 0 0 0-12 12v24a12 12 0 0 0 12 12h12v64h-12a12 12 0 0 0-12 12v24a12 12 0 0 0 12 12h88a12 12 0 0 0 12-12v-24a12 12 0 0 0-12-12z"
        /></g></svg>
        <svg
          v-if="isWarning"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false"
          data-prefix="fad"
          data-icon="exclamation-triangle"
          class="w-5 h-5 text-yellow-400"
          role="img"
          viewBox="0 0 576 512"
        ><g class="fa-group"><path
          class="fa-secondary"
          fill="currentColor"
          d="M569.52 440L329.58 24c-18.44-32-64.69-32-83.16 0L6.48 440c-18.42 31.94 4.64 72 41.57 72h479.89c36.87 0 60.06-40 41.58-72zM288 448a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm38.24-238.41l-12.8 128A16 16 0 0 1 297.52 352h-19a16 16 0 0 1-15.92-14.41l-12.8-128A16 16 0 0 1 265.68 192h44.64a16 16 0 0 1 15.92 17.59z"
          opacity="0.4"
        /><path
          class="fa-primary"
          fill="currentColor"
          d="M310.32 192h-44.64a16 16 0 0 0-15.92 17.59l12.8 128A16 16 0 0 0 278.48 352h19a16 16 0 0 0 15.92-14.41l12.8-128A16 16 0 0 0 310.32 192zM288 384a32 32 0 1 0 32 32 32 32 0 0 0-32-32z"
        /></g></svg>
      </div>
      <div class="ml-3 flex-1 md:flex md:justify-between">
        <p>
          <span
            class="brand-heading text-sm"
            :class="{'text-blue-800': isInformative, 'text-yellow-500': isWarning}"
          >
            {{ title }}
            <br>
          </span>
          <bbob-bbcode
            container="div"
            class="text-sm"
            :class="{'text-blue-700': isInformative, 'text-yellow-400' : isWarning}"
            :plugins="plugins"
          >
            {{ message }}
          </bbob-bbcode>
        </p>
      </div>
    </div>
    <div class="flex mt-2 ml-2">
      <div
        v-if="hasLinkMessage"
        class="mt-4 h-full text-sm md:mt-0 md:ml-6 flex-col md:flex"
      >
        <div class="md:flex-grow" />
        <div>
          <p @click="click">
            <router-link
              :to="linkUrl"
              class="whitespace-nowrap font-bold"
              :class="{'text-blue-800 hover:text-blue-900': isInformative, 'text-yellow-600 hover:text-yellow-700' : isWarning}"

              @click.prevent="linkUrl !== '' && linkUrl !== null"
            >
              {{ linkMessage }}
              <span aria-hidden="true">&rarr;</span>
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ourBbcodePreset from '@/config/bbcodePreset';

export default {

  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    // warning
    // information
    // todo - important
    severity: {
      type: String,
      default: 'information',
    },
    linkMessage: {
      type: String,
      default: '',
    },
    linkUrl: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      plugins: [
        ourBbcodePreset(),
      ],
    };
  },

  computed: {
    isInformative() { return this.severity === 'information'; },
    isWarning() { return this.severity === 'warning'; },
    hasMessage() {
      return (this.message !== '' && this.message !== null) || (this.title !== '' && this.title !== null);
    },
    hasLinkMessage() {
      return this.linkMessage !== '' && this.linkMessage !== null;
    },
  },

  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>
