import { ClubTheme } from '../classes';

// hacky and maybe not needed
const defaultClub = new ClubTheme({
  clubId: 0,
  clubName: 'Golf Club Name',
});

const astburyTest = new ClubTheme({
  clubId: 264,
  clubName: 'Astbury',
  packageLogoPosition: 'Top',
});

const astbury = new ClubTheme({
  clubId: 472,
  clubName: 'Astbury',
  packageLogoPosition: 'Top',
});

const westEssex = new ClubTheme({
  clubId: 211,
  clubName: 'West Essex',
});

const themeList = [
  astburyTest,
  defaultClub,
  westEssex,
  astbury,
];

export { themeList };
