import { Club } from '../classes';

// hacky and maybe not needed
const defaultClub = new Club({
  clubId: 0,
  clubName: 'Golf Club Name',
});

const astbury = new Club({
  clubId: 472,
  clubName: 'The Astbury',
  clubNameShort: 'Astbury',
  mapsLink: 'https://goo.gl/maps/rWe8mZi2Wtv8ZbY47',
  numberOfGuests: 7,
  renewButton: 'Club Astbury Packages',
  email: 'admin@theastbury.com',
  termsAndConditions: [
    'Payment must be made in advance, by choosing a package on our Clarendon Collection portal.',
    'Both our Hyde and Villiers packages are valid for 12 months only.',
    'Any unused home points will be carried over upon renewal into the following year.',
    'If you choose not to renew into the following year, any unused points will be lost.',
    'Bookings should be made online through your individual account on our Clarendon Collection portal.',
    'Bookings can be made up to 30 days in advance.',
    'Bookings can be made on any available tee-time, seven days a week.',
    'Cancellations must be made online through your individual account on the Clarendon Collection portal.',
    'Cancellations cannot be made within 14 days of the tee-time, unless approved by Golf Management.',
    'Any cancellations made less than 14 days prior to the day of play will be non-refundable. Providing we have a minimum of 48 hours’ notice we will permit the transfer of the pre-payment to a future date once within 3 months of the original date. Please note cancellations also apply to a reduction in player numbers.',
    'Golfers choosing the Hyde Package can use points for up to 8 golfers per day.',
    'Golfers choosing the Villiers Package can use the points for up to 12 golfers per day.',
    'Each individual player should check in with the Golf Shop/Reception before play.',
    'We require you to be on the tee, ready for play at least 10 minutes prior to your tee time.',
    'The re-sale of tee-times is not permitted.',
    'Any applicable discounts at The Grove, as part of the package benefits, are valid only for the account holder or main contact.',
    'Any disputes relating to points balances should be made in writing to tee.time@thegrove.co.uk.',
    'The Clarendon Collection cannot be used in conjunction with any other offers.',
  ],
});

const astburyTest = new Club({
  clubId: 264,
  clubName: 'The Astbury',
  clubNameShort: 'Astbury',
  mapsLink: 'https://goo.gl/maps/rWe8mZi2Wtv8ZbY47',
  email: 'admin@theastbury.com',
  numberOfGuests: 7,
  renewButton: 'Club Astbury Packages',
  termsAndConditions: [
    'Payment must be made in advance, by choosing a package on our Clarendon Collection portal.',
    'Both our Hyde and Villiers packages are valid for 12 months only.',
    'Any unused home points will be carried over upon renewal into the following year.',
    'If you choose not to renew into the following year, any unused points will be lost.',
    'Bookings should be made online through your individual account on our Clarendon Collection portal.',
    'Bookings can be made up to 30 days in advance.',
    'Bookings can be made on any available tee-time, seven days a week.',
    'Cancellations must be made online through your individual account on the Clarendon Collection portal.',
    'Cancellations cannot be made within 14 days of the tee-time, unless approved by Golf Management.',
    'Any cancellations made less than 14 days prior to the day of play will be non-refundable. Providing we have a minimum of 48 hours’ notice we will permit the transfer of the pre-payment to a future date once within 3 months of the original date. Please note cancellations also apply to a reduction in player numbers.',
    'Golfers choosing the Hyde Package can use points for up to 8 golfers per day.',
    'Golfers choosing the Villiers Package can use the points for up to 12 golfers per day.',
    'Each individual player should check in with the Golf Shop/Reception before play.',
    'We require you to be on the tee, ready for play at least 10 minutes prior to your tee time.',
    'The re-sale of tee-times is not permitted.',
    'Any applicable discounts at The Grove, as part of the package benefits, are valid only for the account holder or main contact.',
    'Any disputes relating to points balances should be made in writing to tee.time@thegrove.co.uk.',
    'The Clarendon Collection cannot be used in conjunction with any other offers.',
  ],
});


const westEssex = new Club({
  clubId: 211,
  clubName: 'West Essex Golf Club',
  clubNameShort: 'West Essex',
  mapsLink: 'https://goo.gl/maps/pYK8fBHkdfAE7nMC6',
  address: ['West Essex Golf Club', 'Bury Road', 'E4 7QL'],
  email: 'sec@westessexgolfclub.co.uk',
  termsAndConditions: [
    'Payment must be made in advance, by choosing a package on our Clarendon Collection portal.',
    'Both our Hyde and Villiers packages are valid for 12 months only.',
    'Any unused home points will be carried over upon renewal into the following year.',
    'If you choose not to renew into the following year, any unused points will be lost.',
    'Bookings should be made online through your individual account on our Clarendon Collection portal.',
    'Bookings can be made up to 30 days in advance.',
    'Bookings can be made on any available tee-time, seven days a week.',
    'Cancellations must be made online through your individual account on the Clarendon Collection portal.',
    'Cancellations cannot be made within 14 days of the tee-time, unless approved by Golf Management.',
    'Any cancellations made less than 14 days prior to the day of play will be non-refundable. Providing we have a minimum of 48 hours’ notice we will permit the transfer of the pre-payment to a future date once within 3 months of the original date. Please note cancellations also apply to a reduction in player numbers.',
    'Golfers choosing the Hyde Package can use points for up to 8 golfers per day.',
    'Golfers choosing the Villiers Package can use the points for up to 12 golfers per day.',
    'Each individual player should check in with the Golf Shop/Reception before play.',
    'We require you to be on the tee, ready for play at least 10 minutes prior to your tee time.',
    'The re-sale of tee-times is not permitted.',
    'Any applicable discounts at The Grove, as part of the package benefits, are valid only for the account holder or main contact.',
    'Any disputes relating to points balances should be made in writing to tee.time@thegrove.co.uk.',
    'The Clarendon Collection cannot be used in conjunction with any other offers.',
  ],
});

const foxhills = new Club({
  clubId: 234,
  clubName: 'Foxhills Club & Resort',
  clubNameShort: 'Foxhills',
  mapsLink: 'https://g.page/FoxhillsSurrey?share',
  loginText: 'Learn more about Foxhills',
  address: ['Foxhills', 'Stonehill Road', 'Ottershaw', 'Surrey KT16 0EL'],
  dashboardMessage: 'Utilise your points to play golf on our Championship courses 7-days a week. Invite friends, colleagues, clients and customers.',
  introduction: 'Set in 400 acres of pristine countryside, and just 25 miles from London city centre, Foxhills has been Surrey’s favourite playground for over forty years. From the early days as just a golf club, to today’s full country club and resort (offering golf, leisure, spa, tennis, cycling and more), there really is something for everyone at Foxhills.',
  standardPackage: {
    titleStrapline: 'Our Brand New…',
    title: 'Corporate Membership',
    introduction: ['An evolution of our traditional options, our brand new points-based corporate memberships are designed for companies looking for the ultimate flexibility. The naming of both packages, celebrates Foxhills’ rich history as the estate of the mercurial 18th century politician Charles James Fox.',
      'Utilise your points to play golf on either of our two Championship courses or our fabulous par-3 9-hole Manor course. Plus redeem points for our Country Club day passes which can used to experience our healthSpa, gym, swimming pools and tennis courts.',
      'Invite clients, entertain guests or reward members of staff; the choice is yours.'],
  },
  corporatePackage: {
    titleStrapline: 'Our Brand New…',
    title: 'Corporate Membership',
    introduction: ['An evolution of our traditional options, our brand new points-based corporate memberships are designed for companies looking for the ultimate flexibility. The naming of both packages, celebrates Foxhills’ rich history as the estate of the mercurial 18th century politician Charles James Fox.',
      'Utilise your points to play golf on either of our two Championship courses or our fabulous par-3 9-hole Manor course. Plus redeem points for our Country Club day passes which can used to experience our healthSpa, gym, swimming pools and tennis courts.',
      'Invite clients, entertain guests or reward members of staff; the choice is yours.'],
  },
  quotes: ['When you leave, you’ll feel better than when you arrived'],
  explainerImages: [
    {
      link: 'https://www.foxhills.co.uk/dine',
      title: 'Meet',
      desc: 'Experience the most unique meeting venue in Surrey. Just 40 miles from London city centre, Foxhills provides a glamorous retreat for your meeting, ensuring privacy and productivity sit side by side.',
    },
    {
      link: 'https://www.foxhills.co.uk/play',
      title: 'Play',
      desc: 'With unrivalled leisure facilities, the Foxhills Country Club is the ideal place for families and friends to enjoy a relaxed afternoon or an active weekend.',
    },
    {
      link: 'https://www.foxhills.co.uk/spa',
      title: 'Spa',
      desc: 'Welcome to a world of pure relaxation and bliss. Experience the ultimate healthspa break with a visit to Foxhills’ award-winning spa.',
    },
  ],
  renew: {
    title: 'Extend your Foxhills experience',
    body: 'Utilise your points to play golf on our Championship courses 7-days a week. Invite friends, colleagues, clients and customers. Plus unlock significant savings on tuition, retail and selected food outlets.',
  },
  topup: {
    title: 'More points to play',
  },
  termsAndConditions: {
    link: 'https://www.foxhills.co.uk/legal-terms-and-conditions',
    points: [
      'Payment must be made in advance, by choosing a package on our portal.',
      'Both our Fox and Hayton packages are valid for 12 months only.',
      'Any unused home points will be carried over upon renewal into the following year.',
      'If you choose not to renew into the following year, any unused points will be lost.',
      'Bookings should be made online through your individual account on our portal.',
      'Bookings can be made up to 30 days in advance.',
      'Bookings can be made on any available tee-time, seven days a week.',
      'Cancellations must be made online through your individual account on the portal.',
      'Cancellations cannot be made within 14 days of the tee-time, unless approved by Golf Management.',
      'Any cancellations made less than 14 days prior to the day of play will be non-refundable. Providing we have a minimum of 48 hours’ notice we will permit the transfer of the pre-payment to a future date once within 3 months of the original date. Please note cancellations also apply to a reduction in player numbers.',
      'Golfers choosing the Fox Package can use points for up to 8 golfers per day.',
      'Golfers choosing the Hayton Package can use the points for up to 12 golfers per day.',
      'Each individual player should check in with the Golf Shop/Reception before play.',
      'We require you to be on the tee, ready for play at least 10 minutes prior to your tee time.',
      'The re-sale of tee-times is not permitted.',
      'Any applicable discounts at Foxhills, as part of the package benefits, are valid only for the account holder or main contact.',
      'Any disputes relating to points balances should be made in writing to golfservices@foxhills.co.uk.',
    ],
  },
});

const theGrove = new Club({
  clubId: 449,
  clubName: 'The Grove',
  clubNameShort: 'The Grove',
  mapsLink: 'https://goo.gl/maps/NK3TPXWkTeiTt1jp9',
  address: ['The Grove', "Chandler's Cross", 'Hertfordshire WD3 4TG'],
  loginText: 'Learn more about the Clarendon Collection',
  email: 'tee.time@thegrove.co.uk',
  dashboardMessage: 'Utilise your points to play golf on our Championship course 7-days a week. Invite friends, colleagues, clients and customers.',
  introduction: 'Set in 300 acres of stunning Hertfordshire countryside, The Grove is the ultimate five-star retreat. Lovingly restored, the former home of the Earls of Clarendon is situated just 18 miles from London.  With it’s immaculate greens and stunning views, the 7,152 yard championship golf courses is one of the finest in England.',
  standardPackage: {
    titleStrapline: 'Our Brand New…',
    title: 'Clarendon Collection',
    introduction: ['An evolution of the popular Green Book, our new Clarendon Collection is a points-based programme aimed at golfers looking for the ultimate flexibility.', "The naming of Clarendon Collection, and its two packages, celebrates The Grove’s rich history as the family seat of the Earls of Clarendon, a heritage that dates back to the 1600's.", 'Utilise your points to play golf on our Kyle Phillips-designed Championship course 7-days a week. Invite friends, colleagues, clients and customers. Plus unlock significant savings on tuition, retail and selected food outlets.'],
  },
  quotes: [
    {
      text: 'The greens are somewhat undulating but they are really smooth. The golf course is in fantastic shape, the greens are absolutely perfect.',
      name: 'Tiger Woods',
      title: 'Winner of The World Golf Championships 2006',
    },
    {
      text: 'It’s been great to play here over the last few days and the course is in great shape! I’d love to come back and play again!',
      name: 'Alex Noren',
      title: 'Winner of the 2016 British Masters',
    },
    {
      text: 'To everyone at The Grove, thank you for making me look like a great host. Fantastic week!',
      name: 'Luke Donald',
      title: 'Host of the 2016 British Masters',
    },
  ],
  explainerImages: [
    {
      link: 'https://www.thegrove.co.uk/golf/golf-tuition/',
      title: 'Tuition & Practice Facilities',
      desc: 'Whether it’s a warm-up before your round, or a lesson with one of our PGA Professional Team, our grass driving range is the perfect way to improve your golf.',
    },
    {
      link: 'https://www.thegrove.co.uk/golf/plan-your-upcoming-golf-visit/',
      title: 'Rental Items',
      desc: 'Why not hire a golf cart too? With the industry leading state-of-the-art Visage GPS systems installed, our Club Car carts are the perfect way to experience our Championship course.',
    },
    {
      link: 'https://www.thegrove.co.uk/dining/the-stables/',
      title: 'The Stables',
      desc: 'After your round, tuck into The Stables’ menu – homemade, wholesome and wholehearted. Our refreshed restaurant is sporting an elegant new interior. And outside on the terrace, enjoy views across our golf course.',
    },
  ],
  termsAndConditions: [
    'Payment must be made in advance, by choosing a package on our Clarendon Collection portal.',
    'Both our Hyde and Villiers packages are valid for 12 months only.',
    'Any unused home points will be carried over upon renewal into the following year.',
    'If you choose not to renew into the following year, any unused points will be lost.',
    'Bookings should be made online through your individual account on our Clarendon Collection portal.',
    'Bookings can be made up to 30 days in advance.',
    'Bookings can be made on any available tee-time, seven days a week.',
    'Cancellations must be made online through your individual account on the Clarendon Collection portal.',
    'Cancellations cannot be made within 14 days of the tee-time, unless approved by Golf Management.',
    'Any cancellations made less than 14 days prior to the day of play will be non-refundable. Providing we have a minimum of 48 hours’ notice we will permit the transfer of the pre-payment to a future date once within 3 months of the original date. Please note cancellations also apply to a reduction in player numbers.',
    'Golfers choosing the Hyde Package can use points for up to 8 golfers per day.',
    'Golfers choosing the Villiers Package can use the points for up to 12 golfers per day.',
    'Each individual player should check in with the Golf Shop/Reception before play.',
    'We require you to be on the tee, ready for play at least 10 minutes prior to your tee time.',
    'The re-sale of tee-times is not permitted.',
    'Any applicable discounts at The Grove, as part of the package benefits, are valid only for the account holder or main contact.',
    'Any disputes relating to points balances should be made in writing to tee.time@thegrove.co.uk.',
    'The Clarendon Collection cannot be used in conjunction with any other offers.',
  ],
  renew: {
    title: 'Clarendon Collection… Renewal Options',
    body: 'Utilise your points to play golf on our Kyle Phillips-designed Championship course 7-days a week. Invite friends, colleagues, clients and customers. Plus unlock significant savings on tuition, retail and selected food outlets.',
  },
  topup: {
    title: 'Clarendon Collection… Top-up Options',
  },
});

const theGroveTest = new Club({
  clubId: 238,
  clubName: 'The Grove',
  clubNameShort: 'The Grove',
  email: 'tee.time@thegrove.co.uk',
  mapsLink: 'https://goo.gl/maps/NK3TPXWkTeiTt1jp9',
  address: ['The Grove', "Chandler's Cross", 'Hertfordshire WD3 4TG'],
  loginText: 'Learn more about the Clarendon Collection',
  dashboardMessage: 'Utilise your points to play golf on our Championship courses 7-days a week. Invite friends, colleagues, clients and customers.',
  introduction: 'Set in 300 acres of stunning Hertfordshire countryside, The Grove is the ultimate five-star retreat. Lovingly restored, the former home of the Earls of Clarendon is situated just 18 miles from London.  With it’s immaculate greens and stunning views, the 7,152 yard championship golf courses is one of the finest in England.',
  standardPackage: {
    titleStrapline: 'Our Brand New…',
    title: 'Clarendon Collection',
    introduction: ['An evolution of the popular Green Book, our new Clarendon Collection is a points-based programme aimed at golfers looking for the ultimate flexibility.', "The naming of Clarendon Collection, and its two packages, celebrates The Grove’s rich history as the family seat of the Earls of Clarendon, a heritage that dates back to the 1600's.", 'Utilise your points to play golf on our Kyle Phillips-designed Championship course 7-days a week. Invite friends, colleagues, clients and customers. Plus unlock significant savings on tuition, retail and selected food outlets.'],
  },
  quotes: [
    {
      text: 'The greens are somewhat undulating but they are really smooth. The golf course is in fantastic shape, the greens are absolutely perfect.',
      name: 'Tiger Woods',
      title: 'Winner of The World Golf Championships 2006',
    },
    {
      text: 'It’s been great to play here over the last few days and the course is in great shape! I’d love to come back and play again!',
      name: 'Alex Noren',
      title: 'Winner of the 2016 British Masters',
    },
    {
      text: 'To everyone at The Grove, thank you for making me look like a great host. Fantastic week!',
      name: 'Luke Donald',
      title: 'Host of the 2016 British Masters',
    },
  ],
  explainerImages: [
    {
      link: 'https://www.thegrove.co.uk/golf/golf-tuition/',
      title: 'Tuition & Practice Facilities',
      desc: 'Whether it’s a warm-up before your round, or a lesson with one of our PGA Professional Team, our grass driving range is the perfect way to improve your golf.',
    },
    {
      link: 'https://www.thegrove.co.uk/golf/plan-your-upcoming-golf-visit/',
      title: 'Rental Items',
      desc: 'Why not hire a golf cart too? With the industry leading state-of-the-art Visage GPS systems installed, our Club Car carts are the perfect way to experience our Championship course.',
    },
    {
      link: 'https://www.thegrove.co.uk/dining/the-stables/',
      title: 'The Stables',
      desc: 'After your round, tuck into The Stables’ menu – homemade, wholesome and wholehearted. Our refreshed restaurant is sporting an elegant new interior. And outside on the terrace, enjoy views across our golf course.',
    },
  ],
  termsAndConditions: [
    'Payment must be made in advance, by choosing a package on our Clarendon Collection portal.',
    'Both our Hyde and Villiers packages are valid for 12 months only.',
    'Any unused home points will be carried over upon renewal into the following year.',
    'If you choose not to renew into the following year, any unused points will be lost.',
    'Bookings should be made online through your individual account on our Clarendon Collection portal.',
    'Bookings can be made up to 30 days in advance.',
    'Bookings can be made on any available tee-time, seven days a week.',
    'Cancellations must be made online through your individual account on the Clarendon Collection portal.',
    'Cancellations cannot be made within 14 days of the tee-time, unless approved by Golf Management.',
    'Any cancellations made less than 14 days prior to the day of play will be non-refundable. Providing we have a minimum of 48 hours’ notice we will permit the transfer of the pre-payment to a future date once within 3 months of the original date. Please note cancellations also apply to a reduction in player numbers.',
    'Golfers choosing the Hyde Package can use points for up to 8 golfers per day.',
    'Golfers choosing the Villiers Package can use the points for up to 12 golfers per day.',
    'Each individual player should check in with the Golf Shop/Reception before play.',
    'We require you to be on the tee, ready for play at least 10 minutes prior to your tee time.',
    'The re-sale of tee-times is not permitted.',
    'Any applicable discounts at The Grove, as part of the package benefits, are valid only for the account holder or main contact.',
    'Any disputes relating to points balances should be made in writing to tee.time@thegrove.co.uk.',
    'The Clarendon Collection cannot be used in conjunction with any other offers.',
  ],
  renew: {
    title: 'Clarendon Collection… Renewal Options',
    body: 'Utilise your points to play golf on our Kyle Phillips-designed Championship course 7-days a week. Invite friends, colleagues, clients and customers. Plus unlock significant savings on tuition, retail and selected food outlets.',
  },
  topup: {
    title: 'Clarendon Collection… Top-up Options',
  },
});

const playMoreGolfResort = new Club({
  clubId: 151,
  clubName: 'PlayMoreGolf Resort',
  packages: [
    {
      id: 0,
      name: 'Hyde',
      price: '£3,200',
      homePoints: '800',
      playersPerDay: 8,
      shortDescription: '£3,200 for 800 points, plus receive the following benefits',
      longDescription: 'Play up to 50 rounds of golf and receive selected benefits. Manage your account and book golf online.',
      benefits:
        [
          'Play up to 50 rounds of golf',
          'Manage your account and book golf online',
          'Use your points for up to 8 people per day',
          'Includes ability to maintain your handicap',
          '10% off all food and beverage purchased in The Stables restaurant and The Halfway House',
          '10% off services including tuition, retail and rental items',
          '18-hole playing lesson with a member of the PGA golf professional team',
          '55-minute Trackman lesson with a member of the PGA golf professional team',
          '5 driving range passes',
          'Preferential group golf rate',
        ],
    },
    {
      id: 1,
      name: 'Villiers',
      price: '£6,000',
      homePoints: '1500',
      playersPerDay: 12,
      shortDescription: '£6,000 for 1500 points, plus receive the following benefits',
      longDescription: 'Play up to 100 rounds of golf and receive selected benefits. Manage your account and book golf online.',
      benefits:
        ['Play up to 100 rounds of golf',
          'Manage your account and book golf online',
          'Use your points for up to 12 people per day',
          'Includes ability to maintain your handicap',
          '15% off all food and beverage purchased in The Stables restaurant and The Halfway House',
          '15% off services including tuition, retail and rental items',
          '18-hole playing lesson with a member of the PGA golf professional team',
          '120-minute Trackman lesson with a member of the PGA golf professional team',
          '5 driving range passes',
          'Preferential group golf rate'],
    },
  ],
});

const eighteenClubDemo = new Club({
  clubId: 259,
  clubName: 'EighteenClub',
  clubNameShort: 'XVIII',
  mapsLink: 'https://goo.gl/maps/YYMjiV97qSFubLm29',
  loginText: 'Learn more about EighteenClub',
  address: ['XVIII Golf', 'Demo Road', 'Demoton', 'XM4 5AS'],
  dashboardMessage: 'Utilise your points to play golf on our courses 7-days a week. Invite friends, colleagues, clients and customers.',
  introduction: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis quis elit cursus, tempus nisi id, dapibus nulla. Donec commodo nulla purus, ac tristique augue mattis at. Maecenas consequat commodo elit eget feugiat. Ut at sem est. Praesent id luctus quam, sit amet condimentum felis.',
  standardPackage: {
    titleStrapline: 'Our Brand New…',
    title: 'Premium Membership',
    introduction: ['Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis quis elit cursus, tempus nisi id, dapibus nulla. Donec commodo nulla purus, ac tristique augue mattis at. Maecenas consequat commodo elit eget feugiat. Ut at sem est. Praesent id luctus quam, sit amet condimentum felis.',
      'Donec semper consequat massa, non consequat eros consequat nec. Nunc id dapibus nisi. Donec pretium massa vel lectus tincidunt tempus. Nulla facilisi.',
      'Nulla venenatis varius massa vitae bibendum.'],
  },
  corporatePackage: {
    titleStrapline: 'Our Brand New…',
    title: 'Corporate Membership',
    introduction: ['Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis quis elit cursus, tempus nisi id, dapibus nulla. Donec commodo nulla purus, ac tristique augue mattis at. Maecenas consequat commodo elit eget feugiat. Ut at sem est. Praesent id luctus quam, sit amet condimentum felis.',
      'Donec semper consequat massa, non consequat eros consequat nec. Nunc id dapibus nisi. Donec pretium massa vel lectus tincidunt tempus. Nulla facilisi.',
      'Nulla venenatis varius massa vitae bibendum.'],
  },
  quotes: ['Curabitur congue vel ante eu malesuada, non consequat eros consequat nec.'],
  explainerImages: [
    {
      link: 'https://www.xviii.golf',
      title: 'Meet',
      desc: 'Vivamus libero augue, congue ac tincidunt eget, faucibus vel neque. Vestibulum a tincidunt eros. Donec nec est tortor. Aliquam erat volutpat. Donec laoreet dui erat, sit amet ultricies ex fermentum at.',
    },
    {
      link: 'https://www.xviii.golf',
      title: 'Play',
      desc: 'Nunc condimentum justo vitae sem tempus, sollicitudin elementum metus fermentum. Duis at laoreet sem, vel posuere lacus.',
    },
    {
      link: 'https://www.xviii.golf',
      title: 'Spa',
      desc: 'Nunc et risus hendrerit, rutrum magna at, dignissim eros. In vestibulum ex euismod massa ornare, et porta felis bibendum.',
    },
  ],
  renew: {
    title: 'Extend your XVIII experience',
    body: 'Curabitur congue vel ante eu malesuada. Praesent viverra sollicitudin metus, et congue felis commodo ut. Vivamus ullamcorper sollicitudin risus eu pulvinar. Vivamus sollicitudin turpis nec justo imperdiet varius.',
  },
  topup: {
    title: 'More points to play',
  },
  termsAndConditions: {
    link: 'https://www.xviii.golf',
    points: [
      'Payment must be made in advance, by choosing a package on our portal.',
      'Both our Fox and Hayton packages are valid for 12 months only.',
      'Any unused home points will be carried over upon renewal into the following year.',
      'If you choose not to renew into the following year, any unused points will be lost.',
      'Bookings should be made online through your individual account on our portal.',
      'Bookings can be made up to 30 days in advance.',
      'Bookings can be made on any available tee-time, seven days a week.',
      'Cancellations must be made online through your individual account on the portal.',
      'Cancellations cannot be made within 14 days of the tee-time, unless approved by Golf Management.',
      'Any cancellations made less than 14 days prior to the day of play will be non-refundable. Providing we have a minimum of 48 hours’ notice we will permit the transfer of the pre-payment to a future date once within 3 months of the original date. Please note cancellations also apply to a reduction in player numbers.',
      'Each individual player should check in with the Golf Shop/Reception before play.',
      'We require you to be on the tee, ready for play at least 10 minutes prior to your tee time.',
      'The re-sale of tee-times is not permitted.',
      'Any applicable discounts at XVIII, as part of the package benefits, are valid only for the account holder or main contact.',
      'Any disputes relating to points balances should be made in writing to golfservices@xviii.golf.',
    ],
  },
});

const clubList = [
  defaultClub,
  astbury,
  astburyTest,
  westEssex,
  theGrove,
  theGroveTest,
  foxhills,
  playMoreGolfResort,
  eighteenClubDemo,
];

export { clubList };
