const helpRoutes = [
  {
    path: '/Help',
    component: () => import('./help.vue'),
    meta: {
      title: 'Help',
    },
  },
  {
    path: '/Help/:clubName/:clubId',
    component: () => import('./help.vue'),
    meta: {
      title: 'Help',
      overrideStyle: true,
    },
  },
];

export default helpRoutes;
