const settingsStore = {
  state: {
    textSize: 2,
    calendarSync: false,
  },

  mutations: {
    pushTextSize(state, value) {
      state.textSize = value;
    },

    pushCalendarSync(state, value) {
      state.calendarSync = value;
    },
  },

  actions: {
    setTextSize(context, value) {
      return new Promise(() => {
        context.commit('pushTextSize', value);
      });
    },

    setCalendarSync(context, value) {
      if (value) {
        window.cordova.plugins.diagnostic.getCalendarAuthorizationStatus((status) => {
          if (status === window.cordova.plugins.diagnostic.permissionStatus.GRANTED) {
            context.commit('pushCalendarSync', value);
          } else {
            window.cordova.plugins.diagnostic.requestCalendarAuthorization((authStatus) => {
              if (authStatus === window.cordova.plugins.diagnostic.permissionStatus.GRANTED) {
                context.commit('pushCalendarSync', value);
              }
            }, () => {
              alert('Unable to access your calendar, please check the app permissions.');
            });
          }
        }, () => {
          alert('Unable to access your calendar, please check the app permissions.');
        });
      } else {
        context.commit('pushCalendarSync', value);
      }
    },
  },
};

export { settingsStore };
