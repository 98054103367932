<template>
  <div
    class="menuItem relative flex items-center justify-center hover:text-white text-gray-300 transition-all duration-200 cursor-pointer"
    @click="setView()"
    @mouseover="isHovering = true"
    @mouseleave="isHovering = false"
  >
    <div
      class="rounded-lg transition-all duration-250 p-2"
      :class="selectedView === viewName ? 'menuItemBg text-white' : ''"
    >
      <slot name="svg" />
      <MenuTooltip
        :is-hovering="isHovering"
        :arrow-position="arrowPosition"
        :tooltip-width="tooltipWidth"
        :override-position="overridePosition"
      >
        <slot name="tooltip" />
      </MenuTooltip>
    </div>
  </div>
</template>

<script>
import MenuTooltip from './menuTooltip.vue';

export default {

  components: {
    MenuTooltip,
  },

  props: {
    selectedView: {
      type: String,
      default: '',
    },
    viewName: {
      type: String,
      default: '',
    },
    arrowPosition: {
      type: String,
      default: 'middle',
    },
    tooltipWidth: {
      type: String,
      default: 'w-64',
    },
    overridePosition: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      isHovering: false,
    };
  },

  computed: {

  },

  watch: {

  },

  created() {

  },

  methods: {
    setView() {
      this.$emit('updateView', this.viewName);
    },
  },
};


</script>

<style>
.menuItemBg {
  background-color: rgba(112, 123, 129, 0.286)
}

.menuItem {
  width: 5.2rem;
  height: 4rem;
}
</style>
