<template>
  <div
    class="absolute select-none flex"
    :class="getContainerPositionClasses()"
    :style="getContainerPositionStyles()"
  >
    <div
      :class="getArrowPositionClasses()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="caret-left"
        class="w-3 h-5 -mr-1 text-gray-100 transition-all duration-500 delay-500"
        :class="!isHovering ? 'opacity-0' : 'opacity-100'"
        role="img"
        viewBox="0 0 192 512"
      ><path
        fill="currentColor"
        d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z"
      /></svg>
    </div>
    <div
      class="overflow-hidden transition-all duration-500 delay-500 rounded-lg"
      :class="!isHovering ? 'w-0 opacity-0' : tooltipWidth + ' opacity-100'"
    >
      <div :class="tooltipWidth + ' rounded-lg whitespace-nowrap shadow-lg'">
        <div><slot /></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  props: {
    isHovering: {
      type: Boolean,
      default: false,
    },
    arrowPosition: {
      type: String,
      default: 'middle',
    },
    tooltipWidth: {
      type: String,
      default: 'w-64',
    },
    overridePosition: {
      type: String,
      default: null,
    },
  },

  data() {
    return {

    };
  },

  computed: {

  },

  watch: {

  },

  created() {

  },

  methods: {
    getContainerPositionStyles() {
      if (this.arrowPosition === 'bottom') {
        return 'left: 4.7rem; bottom: 0rem';
      }

      if (this.arrowPosition === 'top') {
        return 'left: 4.7rem; top: 0rem';
      }

      return 'left: 4.7rem; bottom: -50%';
    },

    getContainerPositionClasses() {
      if (this.arrowPosition === 'bottom') {
        return 'items-end justify-center';
      }

      if (this.arrowPosition === 'top') {
        return 'items-start justify-center';
      }

      return 'items-center justify-center';
    },

    getArrowPositionClasses() {
      if (this.overridePosition !== null) {
        return this.overridePosition;
      }

      if (this.arrowPosition === 'bottom') {
        return 'mb-5';
      }

      if (this.arrowPosition === 'top') {
        return 'mt-2';
      }

      return '';
    },
  },
};


</script>
