const infoAndUpdatesRoutes = [
  {
    path: '/info',
    name: 'info',
    component: () => import('./infoAndUpdates.vue'),
    meta: {
      isWindowView: true,
    },
    children: [
      {
        path: '',
        name: 'news',
        component: () => import('@/app/shared/components/infoAndUpdates/news.vue'),
        meta: {
          title: 'News from PlayMoreGolf',
          isWindowView: true,
          windowViewTitle: 'News & Updates',
        },
      },
      {
        path: '/',
        redirect: { name: 'news' },
      },
      {
        path: 'updates',
        name: 'updates',
        component: () => import('@/app/shared/components/infoAndUpdates/updates.vue'),
        meta: {
          title: 'Latest Feature Updates',
          isWindowView: true,
          windowViewTitle: 'News & Updates',
        },
      },
    ],
  },
];

export default infoAndUpdatesRoutes;
