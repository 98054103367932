const settingRoutes = [
  {
    path: '/settings',
    name: 'settings',
    component: () => import('./settings.vue'),
    meta: {
      isWindowView: true,
    },
    children: [
      {
        path: '/',
        name: 'settingsHome',
        component: () => import('./index.vue'),
        meta: {
          title: 'Settings',
          isWindowView: true,
          windowViewTitle: 'Settings',
        },
      },
    ],
  },
];

export default settingRoutes;
