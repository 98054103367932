import { services } from '../services';

const buddyStore = {
  state: {
    buddies: undefined,
    pendingBuddies: undefined,
    buddyRequests: undefined,
    lastFetchBuddies: undefined,
    lastFetchPending: undefined,
    lastFetchRequests: undefined,
    shareCode: undefined,
  },

  mutations: {
    updateBuddies(state, buddies) {
      state.buddies = buddies;
      state.lastFetchBuddies = new Date();
    },
    updatePendingBuddies(state, pendingBuddies) {
      state.pendingBuddies = pendingBuddies;
      state.lastFetchPending = new Date();
    },
    updateBuddyRequests(state, buddyRequests) {
      state.buddyRequests = buddyRequests;
      state.lastFetchRequests = new Date();
    },
    updateShareCode(state, code) {
      state.shareCode = code;
    },
    emptyBuddyCache(state) {
      state.buddies = undefined;
      state.pendingBuddies = undefined;
      state.buddyRequests = undefined;
      state.lastFetchBuddies = undefined;
      state.lastFetchPending = undefined;
      state.lastFetchRequests = undefined;
      state.shareCode = undefined;
    },
  },

  actions: {
    async updateBuddies(context) {
      const buddies = await services.buddy.buddies();
      context.commit('updateBuddies', buddies);
    },
    async updatePendingBuddies(context) {
      const pending = await services.buddy.pendingBuddies();
      context.commit('updatePendingBuddies', pending);
    },
    async updateBuddyRequests(context) {
      const requests = await services.buddy.buddyRequests();
      context.commit('updateBuddyRequests', requests);
    },
    async populateShareCode(context) {
      const shareCode = await services.buddy.buddyShareToken();
      context.commit('updateShareCode', shareCode);
    },
    async emptyBuddyStore({ commit }) {
      commit('emptyBuddyCache');
    },
  },

};

export { buddyStore };
