const homeRoutes = [
  {
    path: '',
    component: () => import('./mainRouterView.vue'),
    name: 'home',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        component: () => import('./memberHome/memberHome.vue'),
        name: 'home',
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            component: () => import('./dashboard.vue'),
          },
          {
            path: '/',
            name: 'dashboard',
            component: () => import('./dashboard.vue'),
          },
          {
            path: '/bookings',
            name: 'myBookings',
            component: () => import('@/app/shared/components/member/bookings/bookings.vue'),
          },
          {
            path: '/top-up',
            name: 'Top-up',
            component: () => import('@/app/shared/components/member/topUp/topUp.vue'),
          },
          {
            path: '/renew',
            name: 'Renew',
            component: () => import('@/app/shared/components/member/renew/renew.vue'),
          },
          {
            path: '/statistics',
            name: 'Statistics',
            component: () => import('@/app/shared/components/member/statistics/statistics.vue'),
          },
          {
            path: '/referrals',
            name: 'Referrals',
            component: () => import('@/app/shared/components/member/referrals/referrals.vue'),
          },
        ],
      },
      {
        path: '/explore',
        name: 'explore',
        redirect: { name: 'clubs' },
      },
      {
        path: '/explore/clubs',
        name: 'clubs',
        component: () => import('@/app/booking/explore/explore.vue'),
        meta: {
          requiresAuth: true,
          title: 'Explore Clubs',
          noAutoScroll: true,
        },
      },
      {
        path: '',
        redirect: { name: 'clubs' },
      },
      {
        path: '/buddies',
        component: () => import('@/app/buddy/buddyManagement/buddyHome.vue'),
        meta: {
          title: 'My Buddies',
        },
        children: [
          {
            meta: {
              title: 'My Buddies',
            },
            path: '/',
            name: 'buddyList',
            component: () => import('@/app/shared/components/buddy/buddyManagement.vue'),
          },
          {
            meta: {
              title: 'Buddy Requests',
            },
            path: '/requests',
            name: 'buddyRequests',
            component: () => import('@/app/shared/components/buddy/buddyRequests.vue'),
          },
          {
            meta: {
              title: 'Pending Buddies',
            },
            path: '/pending',
            name: 'pendingBuddies',
            component: () => import('@/app/shared/components/buddy/pendingBuddies.vue'),
          },
          {
            path: '',
            redirect: { name: 'buddyList' },
          },
        ],
      },
      {
        path: '/points',
        component: () => import('@/app/points/points.vue'),
        meta: {
          title: 'Points',
        },
        children: [
          {
            path: '',
            name: 'homePoints',
            component: () => import('@/app/shared/components/points/home/home.vue'),
            meta: {
              title: 'Home Points',
            },
          },
          {
            path: '/',
            redirect: { name: 'home' },
          },
          {
            path: 'flexi',
            name: 'flexi',
            component: () => import('@/app/shared/components/points/flexi/flexi.vue'),
            meta: {
              title: 'Flexi Points',
            },
          },
          {
            path: 'renew',
            name: 'renew',
            component: () => import('@/app/shared/components/member/renew/renew.vue'),
            meta: {
              title: 'Renew',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/',
    redirect: { name: 'home' },
  },
];

export default homeRoutes;
