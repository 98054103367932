import { services } from '../services';

const contentStore = {
  state: {
    lastGiftCardFetch: undefined,
    giftCardMembershipText: undefined,
    giftCardExperienceText: undefined,
    giftCardBannerText: undefined,
    giftCardBannerImage: undefined,
    giftCardCustomText: undefined,
  },

  mutations: {
    updateGiftCardContent(state, payload) {
      state.giftCardBannerImage = payload.bannerImage;
      state.giftCardBannerText = payload.bannerText;
      state.giftCardMembershipText = payload.membershipText;
      state.giftCardExperienceText = payload.experienceText;
      state.giftCardCustomText = payload.customText;
      state.lastGiftCardFetch = new Date();
    },
  },

  actions: {
    async updateGiftCardContent(context) {
      const content = await services.cmsService.getGiftCardPages();
      context.commit('updateGiftCardContent', {
        bannerImage: content.bannerImage,
        bannerText: content.bannerText,
        membershipText: content.membershipGiftCardText,
        experienceText: content.experienceGiftCardText,
        customText: content.customGiftCardText,
      });
    },
  },

};

export { contentStore };
