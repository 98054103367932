import { services } from '../services';
import { clubList } from './lists/clubList';

const clubStore = {
  state: {
    overrideClub: false,
    overridingClubId: 0,
    clubLoaded: false,
    logoUrl: null,
    bannerUrl: null,
    coverUrl: 'cover.jpg',
  },

  getters: {
    getClub: (state, getters, rootState) => {
      if (state.overrideClub) {
        return clubList.find(club => club.clubId === state.overridingClubId) !== undefined
          ? clubList.find(club => club.clubId === state.overridingClubId)
          : clubList.find(club => club.clubId === 0);
      }
      return clubList.find(club => club.clubId === rootState.user.homeClubId) !== undefined
        ? clubList.find(club => club.clubId === rootState.user.homeClubId)
        : clubList.find(club => club.clubId === 0);
    },
  },

  mutations: {
    setOverrideStyle(state, value) {
      state.overrideClub = value;
    },

    setOverridingClubId(state, value) {
      state.clubLoaded = false;
      if (!(isNaN(value))) {
        state.overridingClubId = Number(value);
        services.club.clubDetails(state.overridingClubId).then((data) => {
          state.logoUrl = data.LogoUrl;
          state.bannerUrl = data.BannerUrl;
          state.coverUrl = data.CoverUrl === null ? `${process.env.VUE_APP_BASE_URL}img/cover.jpg` : data.CoverUrl;
          state.clubLoaded = true;
        });
      } else {
        state.overridingClubId = 0;
        state.clubLoaded = true;
      }
    },

    setClubDetails(state, value) {
      state.coverUrl = value.CoverUrl;
      state.bannerUrl = value.BannerUrl;
    },
  },

  actions: {
    getClubDetails(context, value) {
      services.club.clubDetails(value).then((data) => {
        // eslint-disable-next-line no-param-reassign
        data.CoverUrl = data.CoverUrl === null ? 'cover.jpg' : data.CoverUrl;
        context.commit('setClubDetails', data);
      });
    },
  },
};

export { clubStore };
