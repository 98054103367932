const bookingRoutes = [
  {
    path: '/teetimes/:clubId',
    name: 'TeeTimes',
    component: () => import('./clubHouse/clubTeeTimes.vue'),
    meta: {
      requiresAuth: true,
      hideHomeClub: true,
    },
    props: true,
  },

  {
    path: '/teetimes',
    name: 'teeTimesQuery',
    component: () => import('./clubHouse/clubTeeTimes.vue'),
    meta: {
      requiresAuth: true,
      hideHomeClub: true,
      noAutoScroll: true,
    },
    props: true,
  },
];

export default bookingRoutes;
