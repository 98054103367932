const policyRoutes = [
  {
    path: '/termsandpolicies',
    name: 'termsAndPolicies',
    component: () => import('@/app/policies/policies.vue'),
    meta: {
      title: 'Terms & Policies',
      isWindowView: true,
      windowViewTitle: 'Terms & Policies',
    },
  },

];

export default policyRoutes;
