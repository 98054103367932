const clubRoutes = [
  {
    path: '/Club',
    component: () => import('./club.vue'),
    children: [
      {
        path: '/matrix/:clubName/:clubId',
        name: 'PointsMatrix',
        component: () => import('./matrix/viewMatrix.vue'),
        meta: {
          title: 'Points Matrix',
          requiresAuth: false,
          overrideStyle: true,
        },
      },
      {
        path: '/T&Cs/:clubName/:clubId',
        name: 'Terms and Conditions',
        component: () => import('./termsAndConditions/termsAndConditions.vue'),
        meta: {
          title: 'Terms & Conditions',
          requiresAuth: false,
          noLayout: true,
          overrideStyle: true,
        },
      },
      {
        path: '',
        redirect: { name: 'landing' },
      },
    ],
  },
];

export default clubRoutes;
