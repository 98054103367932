

class ClubTheme {
  constructor(params) {
    this.clubId = params.clubId;
    this.packageLogoPosition = params.packageLogoPosition;
    const pathToImages = `club${this.clubId}/`;

    this.cssTheme = `club${this.clubId}`;
    this.logo = params.logo === undefined
      ? `${pathToImages}Logo.png`
      : `${pathToImages}${params.Logo}`;
    this.logoAlt = params.logoAlt === undefined
      ? `${pathToImages}LogoAlt.png`
      : `${pathToImages}${params.logoAlt}`;
    this.cover1 = params.cover1 === undefined
      ? `${pathToImages}Cover1.jpg`
      : `${pathToImages}${params.cover1}`;
    this.cover2 = params.cover2 === undefined
      ? `${pathToImages}Cover2.jpg`
      : `${pathToImages}${params.cover2}`;
    this.logoSmall = params.logoSmall === undefined
      ? `${pathToImages}LogoSmall.png`
      : `${pathToImages}${params.logoSmall}`;
    this.logoSmallAlt = params.logoSmallAlt === undefined
      ? `${pathToImages}LogoSmallAlt.png`
      : `${pathToImages}${params.logoSmallAlt}`;
    this.loginBackground = params.loginBackground === undefined
      ? `${pathToImages}LoginBackground.jpg`
      : `${pathToImages}${params.loginBackground}`;
  }
}

export { ClubTheme };
