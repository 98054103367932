<template>
  <div
    class="bg-white md:bg-transparent text-center text-gray-400 text-sm bg-opacity-95 cursor-pointer"
    style="z-index: 20; margin-bottom: -1px;"
  >
    <div v-if="isAuthenticated">
      <router-link
        to="/"
      >
        <div
          class="p-2 w-3/12 inline-block transition-color transition-150 ease-in-out brand-heading hover:text-secondary-500 md:hover:text-gray-300"
          :class="isCurrentView('Home')? borderLocation + ' border-brand-500 text-secondary-500 md:text-gray-300':''"
          @click="changeView('Home')"
        >
          <div class="h-6 flex items-center justify-center leading-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="home"
              class="h-5 w-5 ml-auto mr-auto"
              role="img"
              viewBox="0 0 576 512"
            >

              <path
                fill="currentColor"
                d="M570.24 247.41L512 199.52V104a8 8 0 0 0-8-8h-32a8 8 0 0 0-7.95 7.88v56.22L323.87 45a56.06 56.06 0 0 0-71.74 0L5.76 247.41a16 16 0 0 0-2 22.54L14 282.25a16 16 0 0 0 22.53 2L64 261.69V448a32.09 32.09 0 0 0 32 32h128a32.09 32.09 0 0 0 32-32V344h64v104a32.09 32.09 0 0 0 32 32h128a32.07 32.07 0 0 0 32-31.76V261.67l27.53 22.62a16 16 0 0 0 22.53-2L572.29 270a16 16 0 0 0-2.05-22.59zM463.85 432H368V328a32.09 32.09 0 0 0-32-32h-96a32.09 32.09 0 0 0-32 32v104h-96V222.27L288 77.65l176 144.56z"
              />

              <g :class="isCurrentView('Home')?'fa-group':'hidden'"><path
                class="fa-secondary"
                fill="currentColor"
                d="M336 463.59V368a16 16 0 0 0-16-16h-64a16 16 0 0 0-16 16v95.71a16 16 0 0 1-15.92 16L112 480a16 16 0 0 1-16-16V300.06l184.39-151.85a12.19 12.19 0 0 1 15.3 0L480 300v164a16 16 0 0 1-16 16l-112-.31a16 16 0 0 1-16-16.1z"
                opacity="0.4"
              /><path
                class="fa-primary"
                fill="currentColor"
                d="M573.32 268.35l-25.5 31a12 12 0 0 1-16.9 1.65L295.69 107.21a12.19 12.19 0 0 0-15.3 0L45.17 301a12 12 0 0 1-16.89-1.65l-25.5-31a12 12 0 0 1 1.61-16.89L257.49 43a48 48 0 0 1 61 0L408 116.61V44a12 12 0 0 1 12-12h56a12 12 0 0 1 12 12v138.51l83.6 68.91a12 12 0 0 1 1.72 16.93z"
              /></g>
            </svg>
          </div>
          <div
            class="leading-none mt-1"
            :class="$isIOS ? 'mb-5' : ''"
          >
            {{ $t("tabs.home") }}
          </div>
        </div>
      </router-link>

      <router-link
        to="/explore/clubs"
      >
        <div
          id="exploreMenuItem"
          class="p-2 w-3/12 inline-block transition-color transition-150 brand-heading hover:text-secondary-500 md:hover:text-gray-300"
          :class="isCurrentView('Explore')? borderLocation + ' border-brand-500 text-secondary-500 md:text-gray-300':''"
          @click="changeView('Explore')"
        >
          <div
            class="h-6 flex items-center justify-center leading-none"
            style="padding-bottom: 2px;"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="flag"
              style="height: 1.08rem; width: 1.08rem;"
              class="ml-auto mr-auto"
              role="img"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M336.174 80c-49.132 0-93.305-32-161.913-32-31.301 0-58.303 6.482-80.721 15.168a48.04 48.04 0 0 0 2.142-20.727C93.067 19.575 74.167 1.594 51.201.104 23.242-1.71 0 20.431 0 48c0 17.764 9.657 33.262 24 41.562V496c0 8.837 7.163 16 16 16h16c8.837 0 16-7.163 16-16v-83.443C109.869 395.28 143.259 384 199.826 384c49.132 0 93.305 32 161.913 32 58.479 0 101.972-22.617 128.548-39.981C503.846 367.161 512 352.051 512 335.855V95.937c0-34.459-35.264-57.768-66.904-44.117C409.193 67.309 371.641 80 336.174 80zM464 336c-21.783 15.412-60.824 32-102.261 32-59.945 0-102.002-32-161.913-32-43.361 0-96.379 9.403-127.826 24V128c21.784-15.412 60.824-32 102.261-32 59.945 0 102.002 32 161.913 32 43.271 0 96.32-17.366 127.826-32v240z"
              />

              <g :class="isCurrentView('Explore')?'fa-group':'hidden'"><path
                class="fa-secondary"
                fill="currentColor"
                d="M512 91.33v277c0 11.31-7.1 21.88-18.5 26.47C317.7 465 281.7 331.25 96 416V102a56.57 56.57 0 0 0 14.64-15c194.19-74.48 184.75 58.25 352-20.08C485.2 56.31 512 68.26 512 91.33z"
                opacity="0.4"
              /><path
                class="fa-primary"
                fill="currentColor"
                d="M120 56a55.93 55.93 0 0 1-24 46v388a22 22 0 0 1-22 22H54a22 22 0 0 1-22-22V102a56 56 0 1 1 88-46z"
              /></g>
            </svg>
          </div>
          <div
            class="leading-none mt-1"
            :class="$isIOS ? 'mb-5' : ''"
          >
            {{ $t("tabs.explore") }}
          </div>
        </div>
      </router-link>

      <router-link
        to="/buddies"
      >
        <div
          id="buddiesMenuItem"
          class="p-2 w-3/12 inline-block transition-color transition-150 brand-heading hover:text-secondary-500 md:hover:text-gray-300"
          :class="isCurrentView('Buddies')? borderLocation + ' border-brand-500 text-secondary-500 md:text-gray-300':''"
          @click="changeView('Buddies')"
        >
          <div class="h-6 flex items-center justify-center text-lg leading-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="user-friends"
              style="height: 1.5rem; width: 1.5rem;"
              class="ml-auto mr-auto"
              role="img"
              viewBox="0 0 640 512"
            >
              <path
                fill="currentColor"
                d="M480 256c53 0 96-43 96-96s-43-96-96-96-96 43-96 96 43 96 96 96zm0-144c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM272.1 276c-11.9 0-23.9 1.7-35.5 5.3-14.2 4.3-29.1 6.7-44.7 6.7s-30.5-2.4-44.7-6.7c-11.6-3.5-23.6-5.3-35.5-5.3-36.3 0-71.6 16.2-92.3 46.9C7.2 341.3 0 363.4 0 387.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-44.8c0-23.8-7.2-45.9-19.6-64.3-20.7-30.7-56-46.9-92.3-46.9zM336 432H48v-44.8c0-28.9 18.4-53.6 44.1-63.1 10.3-3.8 21.6-3.7 31.9 0 22.1 7.9 45 11.9 68 11.9s45.8-4 68.1-11.9c10.3-3.7 21.6-3.8 31.9 0 25.7 9.4 44.1 34.2 44.1 63.1V432zM192 256c61.9 0 112-50.1 112-112S253.9 32 192 32 80 82.1 80 144s50.1 112 112 112zm0-176c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zm431.7 237.1C606.4 291.5 577 278 546.8 278c-27.8 0-34.8 10-66.8 10s-39-10-66.8-10c-13.3 0-26.2 3-38.2 8.1 5.8 5.9 11.3 12 16 18.9 4.7 7 8.6 14.4 12 22 3.3-.7 6.7-1.1 10.2-1.1 17.2 0 29.6 10 66.8 10 37.4 0 49.5-10 66.8-10 15.7 0 29.5 6.7 37.1 17.9 5.3 7.9 8.1 17.1 8.1 26.7V400H416v32c0 5.5-.6 10.8-1.6 16H600c22.1 0 40-17.9 40-40v-37.3c0-19.9-6-38.3-16.3-53.6z"
              />

              <g
                :class="isCurrentView('Buddies')?'fa-group':'hidden'"
                class="fa-group"
              ><path
                class="fa-secondary"
                fill="currentColor"
                d="M480 256a96 96 0 1 0-96-96 96 96 0 0 0 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592a48 48 0 0 0 48-48 111.94 111.94 0 0 0-112-112z"
                opacity="0.4"
              /><path
                class="fa-primary"
                fill="currentColor"
                d="M192 256A112 112 0 1 0 80 144a111.94 111.94 0 0 0 112 112zm76.8 32h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 0 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 268.8 288z"
              /></g>
            </svg>
          </div>
          <div
            class="leading-none mt-1"
            :class="$isIOS ? 'mb-5' : ''"
          >
            {{ $t("tabs.buddies") }}
          </div>
        </div>
      </router-link>

      <router-link
        :to="!expiringSoon ? '/points' : '/points/renew'"
      >
        <div
          v-if="completedFirstPayment && !isExperienceMember"
          class="w-3/12 inline-block"
        >
          <div
            v-if="!expiringSoon"
            id="purchasesMenuItem"
            class="p-2 transition-color transition-150 brand-heading hover:text-secondary-500 md:hover:text-gray-300"
            :class="isCurrentView('Points')? borderLocation + ' border-brand-500 text-secondary-500 md:text-gray-300':''"
            @click="changeView('Points')"
          >
            <div
              class="h-6 flex items-center justify-center leading-none"
              style="padding-bottom: 2px;"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="coins"
                style="height: 1.2rem; width: 1.2rem;"
                class="ml-auto mr-auto"
                role="img"
                viewBox="0 0 576 512"
              >
                <path
                  fill="currentColor"
                  d="M400 208v96H176v-96h224m24-48H152c-13.255 0-24 10.745-24 24v144c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V184c0-13.255-10.745-24-24-24zm144 56h8V112c0-26.51-21.49-48-48-48H48C21.49 64 0 85.49 0 112v104h8c22.091 0 40 17.909 40 40s-17.909 40-40 40H0v104c0 26.51 21.49 48 48 48h480c26.51 0 48-21.49 48-48V296h-8c-22.091 0-40-17.909-40-40s17.909-40 40-40zm-40-38.372c-28.47 14.59-48 44.243-48 78.372s19.53 63.782 48 78.372V400H48v-65.628c28.471-14.59 48-44.243 48-78.372s-19.529-63.782-48-78.372V112h480v65.628z"
                />
                <g
                  class="fa-group"
                  :class="isCurrentView('Points')?'fa-group':'hidden'"
                ><path
                  class="fa-secondary"
                  fill="currentColor"
                  d="M576 208v-96a48 48 0 0 0-48-48H48a48 48 0 0 0-48 48v96a48 48 0 0 1 0 96v96a48 48 0 0 0 48 48h480a48 48 0 0 0 48-48v-96a48 48 0 0 1 0-96zm-96 176H96V128h384z"
                  opacity="0.4"
                /><path
                  class="fa-primary"
                  fill="currentColor"
                  d="M480 128v256H96V128z"
                /></g>
              </svg>
            </div>
            <div
              class="leading-none mt-1"
              :class="$isIOS ? 'mb-5' : ''"
            >
              Purchases
            </div>
          </div>

          <div
            v-else
            id="purchasesMenuItem"
            class="p-2 transition-color transition-150 brand-heading hover:text-secondary-500 md:hover:text-gray-300"
            :class="isCurrentView('Points') || isCurrentView('points') ? borderLocation + ' border-brand-500 text-secondary-500 md:text-gray-300':''"
            @click="changeView('points')"
          >
            <div
              class="h-6 flex items-center justify-center text-lg leading-none"
              style="padding-bottom: 1px;"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="sync-alt"
                style="height: 1.05rem; width: 1.05rem;"
                class="ml-auto mr-auto"
                role="img"
                viewBox="0 0 512 512"
              ><path
                fill="currentColor"
                d="M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z"
              /></svg>
            </div>
            <div
              class="leading-none mt-1"
              :class="$isIOS ? 'mb-5' : ''"
            >
              Renew
            </div>
          </div>
        </div>
      </router-link>

      <router-link
        :to="$isNine ?'/membership/ixrenew' : '/membership/renew'"
      >
        <div
          v-if="!completedFirstPayment || isExperienceMember"
          id="purchasesMenuItem"
          class="p-2 w-3/12 inline-block transition-color transition-150 brand-heading hover:text-secondary-500 md:hover:text-gray-300"
          :class="isCurrentView('packages')? borderLocation + ' border-brand-500 text-secondary-500 md:text-gray-300':''"
        >
          <div class="h-6 flex items-center justify-center text-lg leading-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="ticket-alt"
              style="height: 1.4rem; width: 1.4rem;"
              class="ml-auto mr-auto"
              role="img"
              viewBox="0 0 576 512"
            ><path
              fill="currentColor"
              d="M128 160h320v192H128V160zm400 96c0 26.51 21.49 48 48 48v96c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48v-96c26.51 0 48-21.49 48-48s-21.49-48-48-48v-96c0-26.51 21.49-48 48-48h480c26.51 0 48 21.49 48 48v96c-26.51 0-48 21.49-48 48zm-48-104c0-13.255-10.745-24-24-24H120c-13.255 0-24 10.745-24 24v208c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24V152z"
            /></svg>
          </div>
          <div
            class="leading-none mt-1"
            :class="$isIOS ? 'mb-4' : ''"
          >
            Packages
          </div>
        </div>
      </router-link>
    </div>
    <div v-else>
      <div
        v-if="!isAuthenticated && notLoginPage"
        class="flex justify-end"
      >
        <div class="flex justify-end pr-2">
          <span class="pr-16"> Please log in for access to more features</span>
          <router-link
            :to="{name:'login'}"
          >
            <span class="mr-8 ml-0 text-sm text-cool-gray-200 hover:text-cool-gray-300 transition-all duration-500">
              Login / Register
            </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {

  props: {
    borderLocation: {
      type: String,
      default: 'border-t-4',
    },
  },

  data() {
    return {
      currentView: 'Home',
      platform: '',
    };
  },

  computed: {
    clubIdParam() {
      return this.$route.params.clubid;
    },

    clubId() {
      if (this.homeClubId === 0) {
        this.$store.commit('setOverridingClubId', this.clubIdParam);
        return this.clubIdParam;
      }
      return this.homeClubId;
    },

    ...mapState({
      username: state => state.user.username,
      completedFirstPayment: state => state.user.completedFirstPayment,
      homeClubId: state => state.user.homeClubId,
      coverUrl: state => state.club.coverUrl,
      expiringSoon: state => state.user.expiringSoon,
      isAuthenticated: state => state.user.isAuthenticated,
    }),

    ...mapGetters([
      'isExperienceMember',
    ]),
    notLoginPage() {
      const blah = this.$route;
      return blah === undefined || (blah.name !== 'login' && blah.name !== 'loginForClub');
    },
  },

  watch: {
    $route(to) {
      if (to.path === '/' || to.path === '' || to.path.includes('bookings') || to.path.includes('referrals')) {
        this.currentView = 'Home';
      } else if ((to.path.includes('explore') && !to.path.includes('points-matrix')) || to.path.includes('teetimes')) {
        this.currentView = 'Explore';
      } else if (to.path.includes('Buddies') || to.path.includes('buddies') || to.path.includes('requests') || to.path.includes('pending')) {
        this.currentView = 'Buddies';
      } else if (to.path.includes('points')) {
        this.currentView = 'Points';
      } else if (to.path.includes('Help')) {
        this.currentView = 'Help';
      } else if (to.path.includes('renew')) {
        this.currentView = 'packages';
      } else {
        this.currentView = '';
      }
    },
  },

  created() {
    this.platform = process.env.VUE_APP_CORDOVA_PLATFORM;
    const { path } = this.$router.currentRoute;

    if (path === '/' || path === '' || path.includes('bookings') || path.includes('referrals')) {
      this.currentView = 'Home';
    } else if ((path.includes('explore') && !path.includes('points-matrix')) || path.includes('teetimes')) {
      this.currentView = 'Explore';
    } else if (path.includes('Buddies') || path.includes('buddies') || path.includes('requests') || path.includes('pending')) {
      this.currentView = 'Buddies';
    } else if (path.includes('points')) {
      this.currentView = 'Points';
    } else if (path.includes('renew')) {
      this.currentView = 'packages';
    } else if (path.includes('Help')) {
      this.currentView = 'Help';
    } else {
      this.currentView = '';
    }
  },

  methods: {
    isCurrentView(view) {
      if (view === this.currentView) {
        return true;
      }
      return false;
    },

    changeView(view) {
      this.currentView = view;
      this.$emit('resetWindow');
    },
  },
};
</script>
