const signupRoutes = [
  {
    path: '/membership/',
    name: 'membership',
    component: () => import('./signup.vue'),
    children: [
      {
        path: 'club',
        name: 'membershipClub',
        component: () => import('./club/selectClub.vue'),
        meta: {
          title: 'Select a Club',
          requiresAuth: false,
          noLayoutMobile: true,
          hideFooter: true,
        },
      },
      {
        path: 'redeem/:type',
        name: 'membershipRedeem',
        component: () => import('./club/selectClub.vue'),
        meta: {
          title: 'Redeem a Gift Card',
          requiresAuth: false,
          noLayoutMobile: true,
          hideFooter: true,
        },
      },
      {
        path: 'redeem/:type/:code',
        name: 'membershipRedeem2',
        component: () => import('./club/selectClub.vue'),
        meta: {
          title: 'Redeem a Gift Card',
          requiresAuth: false,
          noLayoutMobile: true,
          hideFooter: true,
        },
      },
      {
        path: 'package/:clubId/:promocode',
        name: 'membershipPackagePromoCode',
        component: () => import('./package/package.vue'),
        meta: {
          title: 'Membership Package',
          requiresAuth: false,
          noLayoutMobile: true,
          hideFooter: true,
        },
      },
      {
        path: 'package/:clubId/:clubName',
        name: 'membershipPackage2',
        component: () => import('./package/package.vue'),
        props: true,
        meta: {
          title: 'Membership Package',
          requiresAuth: false,
          noLayoutMobile: true,
          hideFooter: true,
        },
      },
      {
        path: 'package/:clubId/:clubName/:promocode',
        name: 'membershipPackage3',
        component: () => import('./package/package.vue'),
        meta: {
          title: 'Membership Package',
          requiresAuth: false,
          noLayoutMobile: true,
          hideFooter: true,
        },
      },
      {
        path: 'corporatepackage/:clubId/:clubName/',
        name: 'membershipPackagesCorporate',
        component: () => import('./package/package.vue'),
        props: true,
        meta: {
          title: 'Corporate Package',
          requiresAuth: false,
          noLayoutMobile: true,
          hideFooter: true,
        },
      },
      {
        path: 'registerix/:bundleid/:clubid/:corporate',
        name: 'nineRegister',
        component: () => import('./register/nineRegister.vue'),
        meta: {
          title: 'Register',
          requiresAuth: false,
          noLayout: true,
        },
      },
      {
        path: '/membership/welcomeix',
        name: 'welcomeIx',
        component: () => import('./welcome/welcomeIx.vue'),
        meta: {
          title: 'Welcome!',
          noLayout: true,
        },
      },
      {
        path: '/membership/welcome-back-Ix',
        name: 'welcomeBackIx',
        component: () => import('./welcome/welcomeBackIx.vue'),
        meta: {
          title: 'Welcome Back!',
          noLayout: true,
        },
      },
      {
        path: '/membership/welcome',
        name: 'welcome',
        component: () => import('./welcome/welcome.vue'),
        meta: {
          title: 'Welcome!',
          requiresAuth: false,
        },
      },
      {
        path: '/membership/welcome-back',
        name: 'welcomeBack',
        component: () => import('./welcome/welcomeBack.vue'),
        meta: {
          title: 'Welcome Back!',
          requiresAuth: false,
        },
      },
      {
        path: 'package/:clubId/',
        name: 'membershipPackage',
        component: () => import('./package/package.vue'),
        meta: {
          title: 'Membership Package',
          requiresAuth: false,
          noLayoutMobile: true,
          hideFooter: true,
        },
      },
      {
        path: 'ixpackages/:clubId/:corporate?',
        name: 'ninePackages',
        component: () => import('./package/ninePackages.vue'),
        meta: {
          title: 'Packages',
          requiresAuth: false,
          noLayoutMobile: true,
        },
      },
      // Doesn't belong here
      {
        path: 'renew/:clubId/:code',
        name: 'renewPromocode',
        component: () => import('../renew/renew.vue'),
        meta: {
          title: 'Membership Package',
          requiresAuth: false,
          noLayoutMobile: true,
          hideFooter: true,
        },
      },
      // Doesn't belong here either
      {
        path: 'renew',
        name: 'renewJimHack',
        component: () => import('../renew/renew.vue'),
        meta: {
          title: 'Membership Package',
          requiresAuth: false,
          noLayoutMobile: true,
          hideFooter: true,
        },
      },
      {
        path: 'register/:bundleid/:clubid',
        name: 'corporateRegister',
        component: () => import('./register/register.vue'),
        meta: {
          title: 'Create an Account',
          requiresAuth: false,
          noLayoutMobile: true,
        },
      },
      {
        path: 'register/:bundleid/:clubid/:code',
        name: 'membershipRegister',
        component: () => import('./register/register.vue'),
        meta: {
          title: 'Create an Account',
          requiresAuth: false,
          noLayoutMobile: true,
          hideFooter: true,
        },
      },
      {
        path: 'register/:bundleid/:clubid',
        name: 'membershipRegister2',
        component: () => import('./register/register.vue'),
        meta: {
          title: 'Create an Account',
          requiresAuth: false,
          noLayoutMobile: true,
          hideFooter: true,
        },
      },
      {
        path: 'payment',
        name: 'payment',
        component: () => import('./payment/payment.vue'),
        meta: {
          title: 'Payment',
          requiresAuth: true,
          noLayoutMobile: true,
          hideFooter: true,
        },
      },
      {
        path: 'ixpayment',
        name: 'ninePayment',
        component: () => import('./payment/payment.vue'),
        meta: {
          title: 'Payment',
          requiresAuth: true,
          noLayoutMobile: true,
          noLayout: true,
        },
      },
      {
        path: 'processing/:renewal/:newClub',
        name: 'processing',
        component: () => import('./payment/processing.vue'),
        meta: {
          title: 'Payment processing',
          requiresAuth: false,
          noLayoutMobile: true,
          hideFooter: true,
        },
      },
      {
        path: 'ixprocessing/:renewal/:newClub',
        name: 'nineProcessing',
        component: () => import('./payment/processing.vue'),
        meta: {
          title: 'Payment Processing',
          requiresAuth: false,
          noLayoutMobile: true,
          noLayout: true,
        },
      },
    ],
  },
  {
    path: '/membership/payment-complete',
    name: 'paymentComplete',
    component: () => import('./welcome/paymentComplete.vue'),
    meta: {
      title: 'Payment Complete',
      requiresAuth: false,
      hideFooter: true,
    },
  },
  {
    path: '/membership/welcome',
    name: 'welcome',
    component: () => import('./welcome/welcome.vue'),
    meta: {
      title: 'Welcome!',
      requiresAuth: false,
      hideFooter: true,
    },
  },
  {
    path: '/membership/welcome-back',
    name: 'welcomeBack',
    component: () => import('./welcome/welcomeBack.vue'),
    meta: {
      title: 'Welcome Back!',
      requiresAuth: false,
      hideFooter: true,
    },
  },
  {
    path: '/membership/welcome-experience',
    name: 'welcomeExperience',
    component: () => import('./welcome/welcomeExperience.vue'),
    meta: {
      title: 'Welcome!',
      requiresAuth: false,
      hideFooter: true,
    },
  },
];

export default signupRoutes;
