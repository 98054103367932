import { Services } from 'pmg-services';

const services = new Services({
  apiUrl: `${process.env.VUE_APP_API_URL}/api/`,
  withCredentials: true,
  nodeEnv: process.env.VUE_APP_ENV,
  redirect401: process.env.VUE_APP_ENV_RESTRICTED !== 'true',
  unauthorizedRoute: `${process.env.VUE_APP_BASE_URL}Login`,
  stripePK: process.env.VUE_APP_STRIPE_API_KEY,
});

export { services };
