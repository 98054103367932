const checkoutRoutes = [
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('./checkout/checkout.vue'),
    meta: {
      title: 'Checkout',
      requiresAuth: true,
      hideFooter: true,
    },
  },
  {
    path: '/top-up-complete',
    name: 'topupComplete',
    component: () => import('./topup/topUpComplete.vue'),
    meta: {
      title: 'Top-up Successful',
      hideFooter: true,
    },
  },
  {
    path: '/purchase-complete',
    name: 'purchaseComplete',
    component: () => import('./voucher/voucherPurchaseComplete.vue'),
    meta: {
      title: 'Voucher Purchase Successful',
      hideFooter: true,
    },
  },
];

export default checkoutRoutes;
