const supportRoutes = [
  {
    path: '/support',
    name: 'support',
    component: () => import('./support.vue'),
    meta: {
      isWindowView: true,
    },
    children: [
      {
        path: '/',
        redirect: { name: 'helpSupport' },
      },
      {
        path: 'helpSupport',
        name: 'helpSupport',
        component: () => import('@/app/help/help.vue'),
        meta: {
          title: 'Help',
          isWindowView: true,
          windowViewTitle: 'Help & Contact',
        },
      },
      {
        path: 'contactSupport',
        name: 'contactSupport',
        component: () => import('@/app/shared/components/support/contact.vue'),
        meta: {
          title: 'Contact Us',
          isWindowView: true,
          windowViewTitle: 'Help & Contact',
        },
      },
      {
        path: 'feedbackSupport',
        name: 'feedbackSupport',
        component: () => import('@/app/shared/components/support/feedback.vue'),
        meta: {
          title: 'Submit your Feedback',
          isWindowView: true,
          windowViewTitle: 'Help & Contact',
        },
      },
    ],
  },
];

export default supportRoutes;
