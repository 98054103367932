const giftCardRoutes = [
  {
    path: '/BlackFridayEarlyAccess',
    component: () => import ('./earlyAccessForm.vue'),
    name: 'BlackFridayEarlyAccess',
    meta: {
      title: 'PlayMoreGolf Black Friday Early Access',
      isWindowView: true,
      windowViewTitle: 'Black Friday Early Access',
    },
  },
  {
    path: '/BlackFridayEarlyAccess/:code',
    component: () => import ('./earlyAccessForm.vue'),
    name: 'BlackFridayEarlyAccess',
    meta: {
      title: 'PlayMoreGolf Black Friday Early Access',
      isWindowView: true,
      windowViewTitle: 'Black Friday Early Access',
    },
  },
  {
    // Maybe should live in a seperate route file? Voucher-routes?
    path: '/redeem',
    component: () => import ('./redeem.vue'),
    name: 'redeem',
    meta: {
      title: 'Redeem',
      requiresAuth: true,
      isWindowView: true,
      windowViewTitle: 'Redeem',
    },
  },
  {
    // Maybe should live in a seperate route file? Voucher-routes?
    path: '/redeem/:type/:code',
    component: () => import ('./redeemType.vue'),
    name: 'redeemType',
    meta: {
      title: 'Redeem',
      requiresAuth: false,
      windowViewTitle: 'Redeem',
    },
  },
  {
    path: '/purchaseGiftCard',
    component: () => import ('./purchaseGiftCard.vue'),
    name: 'purchaseGiftCard',
    meta: {
      title: 'Purchase Gift Card',
    },
  },
  {
    path: '/purchaseGiftCard2',
    component: () => import ('./purchaseGiftCard2.vue'),
    name: 'purchaseGiftCard',
    meta: {
      title: 'Purchase Gift Card',
      requiresAuth: false,
    },
    children: [
      {
        path: '/',
        meta: {
          name: 'unselectedGiftCard',
          requiresAuth: false,
          subMenu: true,
        },
      },
      {
        path: '/purchaseMembershipGiftCard',
        component: () => import ('@/app/shared/components/giftCards/purchase/purchaseMembershipGiftCard.vue'),
        name: 'purchaseMembershipGiftCard',
        meta: {
          title: 'Purchase Membership Gift Card',
          requiresAuth: false,
          subMenu: true,
        },
      },
      {
        path: '/purchaseExperienceGiftCard',
        component: () => import ('@/app/shared/components/giftCards/purchase/purchaseExperienceGiftCard.vue'),
        name: 'purchaseExperienceGiftCard',
        meta: {
          title: 'Purchase Experience Gift Card',
          requiresAuth: false,
          subMenu: true,
        },
      },
      {
        path: '/purchaseCustomGiftCard',
        component: () => import ('@/app/shared/components/giftCards/purchase/purchaseCustomGiftCard.vue'),
        name: 'purchaseCustomGiftCard',
        meta: {
          title: 'Purchase Custom Gift Card',
          requiresAuth: false,
          subMenu: true,
        },
      },
    ],
  },
];

export default giftCardRoutes;
