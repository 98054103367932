<template>
  <div>
    <div
      id="header"
      class="header flex items-center justify-between md:pr-6 h-16 w-full bg-white
    text-gray-800 border-b md:border-0 z-30"
    >
      <div class="flex items-center justify-between pl-8 pr-8 md:p-0 md:justify-end w-full">
        <!-- <div class="locale-dropdown mr-4 text-gray-800">
          <select
            v-model="locale"
          >
            <option
              v-for="(lang, i) in languageArray"
              :key="`lang${i}`"
              :value="lang"
            >
              {{ lang }}
            </option>
          </select>
        </div> -->

        <div
          class="md:hidden"
        >
          <div
            v-if="!openSideMenu"
          >
            <div
              class="relative bg-gradient-to-br from-secondary-300 to-secondary-700 w-9 h-9 flex items-center text-center justify-center leading-none text-xs rounded-full text-white transition-all"
              :class="firstProfileOpen ? 'bounce' : ''"
              @click.stop="sideMenuClick()"
            >
              <span v-if="isAuthenticated">
                {{ forename.charAt(0) }}{{ surname.charAt(0) }}
              </span>
              <span v-else>
                . . .
              </span>
              <div class="absolute inset-x-0 top-4.5 w-9 tracking-wider text-center ">
                <span
                  v-if="isAuthenticated"
                  class="w-3 justify-center"
                >
                  ...
                </span>
              </div>
            </div>
          </div>
          <div
            v-else
            class="text-2xl"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="times"
              class="text-gray-800 w-7 h-7 ml-auto mr-auto"
              role="img"
              viewBox="0 0 320 512"
            ><path
              fill="currentColor"
              d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
            /></svg>
          </div>
        </div>


        <div
          v-show="!summaryLoading && !enableSqueeze"
          class="hidden md:block mr-2"
        >
          <div>
            <span
              v-if="!refreshingPoints && flexiPoints === 0 && homePoints === 0 && completedFirstPayment"
              class="text-sm font-light tracking-wide text-red-700 mr-4"
            >
              <span v-if="!IsExpired">Please top-up or renew</span>
              <span v-else>Renewal required</span>
            </span>
            <span
              class="text-sm font-light tracking-wide"
            >
              <span>
                {{ homePoints }} {{ $t('commonUi.home') }} &bull; {{ flexiPoints }} {{ $t('commonUi.flexi') }}
              </span>
            </span>
          </div>
        </div>

        <div
          v-show="!summaryLoading && !enableSqueeze"
          class="hidden md:block w-3 h-3 mr-4 ml-2 cursor-pointer text-cool-600 hover:text-gray-800"
          style="margin-top: 2px;"
          @click="refreshPoints"
        >
          <svg
            :class="pointsLoading?'rotate':''"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            data-prefix="fad"
            data-icon="sync-alt"
            role="img"
            viewBox="0 0 512 512"
          ><g class="fa-group"><path
            class="fa-secondary"
            fill="currentColor"
            d="M8 454.06V320a24 24 0 0 1 24-24h134.06c21.38 0 32.09 25.85 17 41l-41.75 41.75A166.82 166.82 0 0 0 256.16 424c77.41-.07 144.31-53.14 162.78-126.85a12 12 0 0 1 11.65-9.15h57.31a12 12 0 0 1 11.81 14.18C478.07 417.08 377.19 504 256 504a247.14 247.14 0 0 1-171.31-68.69L49 471c-15.15 15.15-41 4.44-41-16.94z"
            opacity="0.4"
          /><path
            class="fa-primary"
            fill="currentColor"
            d="M12.3 209.82C33.93 94.92 134.81 8 256 8a247.14 247.14 0 0 1 171.31 68.69L463 41c15.12-15.12 41-4.41 41 17v134a24 24 0 0 1-24 24H345.94c-21.38 0-32.09-25.85-17-41l41.75-41.75A166.8 166.8 0 0 0 255.85 88c-77.46.07-144.33 53.18-162.79 126.85A12 12 0 0 1 81.41 224H24.1a12 12 0 0 1-11.8-14.18z"
          /></g></svg>
        </div>

        <div class="hidden md:block mr-2 font-light tracking-wide text-sm">
          {{ username }}
        </div>

        <div
          v-if="isAuthenticated"
          class="mx-2"
        >
          <div
            class="inline-block"
          >
            <div class="relative inline-block text-base">
              <div @click.stop="switchBasketMenuState()">
                <svg
                  style="width: 1.125rem; height: 1.125rem;"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="shopping-basket"
                  class="fas fa-shopping-basket -mt-1 inline-block cursor-pointer select-none text-xl md:text-base hover:text-gray-500 transition-all duration-500"
                  role="img"
                  viewBox="0 0 576 512"
                ><path
                  fill="currentColor"
                  d="M576 216v16c0 13.255-10.745 24-24 24h-8l-26.113 182.788C514.509 462.435 494.257 480 470.37 480H105.63c-23.887 0-44.139-17.565-47.518-41.212L32 256h-8c-13.255 0-24-10.745-24-24v-16c0-13.255 10.745-24 24-24h67.341l106.78-146.821c10.395-14.292 30.407-17.453 44.701-7.058 14.293 10.395 17.453 30.408 7.058 44.701L170.477 192h235.046L326.12 82.821c-10.395-14.292-7.234-34.306 7.059-44.701 14.291-10.395 34.306-7.235 44.701 7.058L484.659 192H552c13.255 0 24 10.745 24 24zM312 392V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm112 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24zm-224 0V280c0-13.255-10.745-24-24-24s-24 10.745-24 24v112c0 13.255 10.745 24 24 24s24-10.745 24-24z"
                /></svg>
                <NotificationAlert
                  v-if="basket !== null && basket !== undefined"
                  :number-of-notifications="1"
                />
              </div>
              <BasketMenu
                v-if="openBasketMenu"
                v-click-outside="closeBasketMenu"
                :enable-squeeze="enableSqueeze"
              />
            </div>
          </div>
        </div>

        <div
          v-if="isAuthenticated"
          class="ml-2 mr-2 relative"
          @click.stop="openNotifications()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            class="cursor-pointer w-4 h-4  -mt-1 inline-block select-none text-xl md:text-base hover:text-gray-500 transition-all duration-500"
            role="img"
            viewBox="0 0 448 512"
          ><path
            fill="currentColor"
            d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"
          /></svg>

          <NotificationAlert
            :number-of-notifications="notificationCount"
          />
          <NotificationMenu
            v-if="openNotificationMenu"
            v-click-outside="closeNotifications"
            :notifications="notifications"
            @close="$store.dispatch('setNotificationMenuState', false)"
          />
        </div>
        <div v-if="isAuthenticated">
          <div
            class="ml-1"
            @click="openLogoutConfirmModal = true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="sign-out-alt"
              style="width: 1.075rem; height: 1.075rem"
              class="hover:text-gray-500 cursor-pointer transition-all duration-500"
              role="img"
              viewBox="0 0 512 512"
            ><path
              fill="currentColor"
              d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"
            /></svg>
          </div>
        </div>

        <div v-if="!isAuthenticated && notLoginPage">
          <div>
            <router-link
              :to="{name:'login'}"
            >
              <span class="mr-8 ml-0 text-sm hover:text-cool-gray-200">
                Login / Register
              </span>
            </router-link>
          </div>
        </div>
        <div v-if="!isAuthenticated && !notLoginPage">
          <div>
            <router-link :to="{name:'membershipClub', query: {voucher: true}}">
              <span class="mr-8 ml-0 text-sm hover:text-cool-gray-200">
                Begin Voucher Redeem
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <WarningConfirmModal
      v-if="openLogoutConfirmModal"
      :title="$t('commonUi.logout')"
      :message="$t('logout.areYouSure')"
      @result="logoutResult"
    />

    <transition name="fade">
      <NotificationPopup
        v-if="openNotificationPopup"
        :notification="notificationPopup"
        @click="closePopup"
      />
    </transition>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { languages } from '@/i18n';
import { Notification } from 'pmg-services';
import { hubConnection } from 'signalr-no-jquery';
import WarningConfirmModal from '@/app/shared/components/base/modal/warningConfirmModal.vue';
import NotificationMenu from '@/app/shared/components/notification/notificationMenu.vue';
import NotificationAlert from '@/app/shared/components/base/notificationAlert/notificationAlert.vue';
import NotificationPopup from '@/app/shared/components/notification/notificationPopup.vue';
import BasketMenu from '@/app/shared/components/basket/basketMenu.vue';
import { environment, nodeEnv } from '@/environment/environment';
import { services } from '../../../services';

export default {

  components: {
    WarningConfirmModal,
    BasketMenu,
    NotificationMenu,
    NotificationAlert,
    NotificationPopup,
  },

  data() {
    return {
      menu: undefined,
      availableThemes: [],
      currentThemeId: 0,
      buddyRequests: 0,
      notificationCount: 0,
      notifications: undefined,
      buddy: undefined,
      environment: undefined,

      openNotificationPopup: false,
      openAcceptBuddy: false,
      openDeclineBuddy: false,
      openLogoutConfirmModal: false,
      firstProfileOpen: false,

      languageArray: [],

      notificationPopup: {
        type: 1,
        action: 0,
        object: {
          Title: 'Trophy Unlocked',
          Description: 'You have unlocked the Enthusiast trophy',
        },
      },

      lastKnownScrollY: 0,
      currentScrollY: 0,
      ticking: false,
      eleHeader: null,
      classes: {
        pinned: 'header-pin',
        unpinned: 'header-unpin',
      },
    };
  },

  computed: {
    ...mapState({
      homePoints: state => state.user.homePoints,
      flexiPoints: state => state.user.flexiPoints,
      pointsLoading: state => state.user.refreshingPoints,
      summaryLoading: state => state.user.refreshingMemberSummary,
      username: state => state.user.username,
      isAuthenticated: state => state.user.isAuthenticated,
      IsImpersonating: state => state.user.IsImpersonating,
      IsExpired: state => state.user.IsExpired,
      userGuid: state => state.user.userGuid,
      basket: state => state.basket.basket,
      openBasketMenu: state => state.layout.openBasketMenu,
      squeezePage: state => state.theme.squeezePage,
      completedFirstPayment: state => state.user.completedFirstPayment,
      forename: state => state.user.forename,
      surname: state => state.user.surname,
      openNotificationMenu: state => state.layout.openNotificationMenu,
      openSideMenu: state => state.layout.openSideMenu,
      refreshingPoints: state => state.user.refreshingPoints,
    }),

    ...mapGetters({
      currentTheme: 'getCurrentTheme',
      club: 'getClub',
    }),

    enableSqueeze() {
      if (!this.squeezePage && !this.IsExpired && this.completedFirstPayment) {
        return false;
      }
      return true;
    },
    notLoginPage() {
      const blah = this.$route;
      return blah === undefined || (blah.name !== 'login' && blah.name !== 'loginForClub');
    },

    locale: {
      get() {
        return this.$store.state.user.locale; // can't map state because need to set too
      },
      set(newVal) {
        // $moment is undefined in the store so we set the locale here
        this.$moment.locale(newVal);
        this.$store.dispatch('changeLocale', newVal);
        window.location.reload(); // some stuff is passed as value so need refresh to take effect $forceUpdate doesn't cut it :/
      },
    },
  },

  // watch: {
  //   enableSqueeze(value) {
  //     if (!value) {
  //       this.initialize();
  //     }
  //   },
  // },

  mounted() {
    this.environment = nodeEnv;
    this.languageArray = languages;

    if (this.isAuthenticated) {
      this.getNumberOfBuddyRequests();
      this.getNumberOfUnreadNotifications();
      if (!this.$isMobileApp) {
        this.initializeSignalR();
      }
    }

    this.popupItem = this.$el;
    this.eleHeader = document.getElementById('header');
    document.addEventListener('scroll', this.onScroll, false);

    if (JSON.parse(localStorage.getItem('firstProfileOpen')) === null) {
      this.firstProfileOpen = true;
    }
  },

  methods: {
    onScroll() {
      this.currentScrollY = window.pageYOffset;
      if (this.currentScrollY > 50 || this.currentScrollY === 0) {
        this.requestTick();
      }
    },

    requestTick() {
      if (!this.ticking) {
        requestAnimationFrame(this.update);
      }
      this.ticking = true;
    },

    update() {
      if (this.currentScrollY < this.lastKnownScrollY) {
        this.pin();
      } else if (this.currentScrollY > this.lastKnownScrollY) {
        this.unpin();
      }
      this.lastKnownScrollY = this.currentScrollY;
      this.ticking = false;
    },

    pin() {
      if (this.eleHeader.classList.contains(this.classes.unpinned)) {
        this.eleHeader.classList.remove(this.classes.unpinned);
        this.eleHeader.classList.add(this.classes.pinned);
      }
    },

    unpin() {
      if (this.eleHeader.classList.contains(this.classes.pinned) || !this.eleHeader.classList.contains(this.classes.unpinned)) {
        this.eleHeader.classList.remove(this.classes.pinned);
        this.eleHeader.classList.add(this.classes.unpinned);
      }
    },

    sideMenuClick() {
      this.$store.dispatch('switchSideMenuState');

      if (this.firstProfileOpen) {
        localStorage.setItem('firstProfileOpen', JSON.stringify(true));
        this.firstProfileOpen = false;
      }
    },

    getClubLink() {
      if (this.isAuthenticated) {
        return '/';
      }
      return `/Club/TheGrove/${this.club.clubId}`;
    },

    logoutResult(result) {
      if (result) {
        this.logout();
      } else {
        this.openLogoutConfirmModal = false;
      }
    },

    logout() {
      if (!this.IsImpersonating) {
        services.account.logout().then(() => {
          const replacePunctuation = str => str.replace(/[^0-9a-z-A-Z]/g, '').replace(/ +/, ' ');
          this.openLogoutConfirmModal = false;

          const homeClubName = replacePunctuation(this.$store.state.user.homeClubName);
          const homeClubId = this.$store.state.user.homeClubId;

          this.$store.commit('toggleHideGlobalLoader', true);
          this.$store.dispatch('setAuth', false);
          this.$store.dispatch('emptyMemberSummary');
          this.$store.dispatch('emptyPackages');
          this.$store.dispatch('emptyBuddyStore');
          if (this.$isStandard) {
            this.$router.push({ name: 'login' });
          } else {
            this.$router.push({ name: 'loginForClub', params: { clubName: homeClubName, clubId: homeClubId } });
          }
        });
      } else {
        services.account.stopImpersonating().then((data) => {
          const url = `${process.env.VUE_APP_ADMIN_URL}/#!/memberInfo?guid=${this.userGuid}`;

          window.location.assign(url);
          window.location = url;

          if (!data) {
            services.account.logout().then(() => {
              this.$store.commit('toggleHideGlobalLoader', true);
              this.$store.dispatch('setAuth', false);
              this.$store.dispatch('emptyMemberSummary');
              this.$store.dispatch('emptyPackages');

              this.openLogoutConfirmModal = false;
              const loginPath = '/login';
              this.$router.push(`${loginPath}`);
            });
          }
        });
      }
    },

    refreshPoints() {
      this.$store.dispatch('refreshPoints', true);
    },

    switchBasketMenuState() {
      this.$store.dispatch('switchBasketMenuState');
      this.$store.dispatch('setNotificationMenuState', false);
    },

    closeBasketMenu() {
      this.$store.dispatch('setBasketMenuState', false);
    },

    openNotifications() {
      if (this.openBasketMenu) {
        this.$store.dispatch('setBasketMenuState', false);
      }

      this.$store.dispatch('setNotificationMenuState', true);
      this.openNotificationPopup = false;

      if (this.notificationCount > 0) {
        this.notificationCount = 0;
        services.notification.allNotificationsAsRead();
      }
    },

    closeNotifications() {
      this.$store.dispatch('setNotificationMenuState', false);
    },

    getNumberOfBuddyRequests() {
      services.buddy.buddyRequestCount().then((data) => {
        this.buddyRequests = data;
      });
    },

    getNumberOfUnreadNotifications() {
      services.notification.unreadNotificationCount().then((data) => {
        this.notificationCount = data;
      });
    },

    initializeSignalR() {
      const connection = hubConnection(`${environment}/signalr/notificationHub`);
      const hubProxy = connection.createHubProxy('notificationHub');

      const vm = this;
      hubProxy.on('addNewMessageToPage', (title, desc, type, action, id) => {
        const notification = new Notification(id, null, false, null, null, type, action, { Title: title, Description: desc });
        vm.notificationReceived(notification);
      });

      connection.start();
    },

    notificationReceived(notification) {
      if (notification.type !== 1 || this.$isStandard) {
        this.notificationCount += 1;
        this.notificationPopup = notification;
        this.openNotificationPopup = true;

        setTimeout(() => {
          this.openNotificationPopup = false;
        }, 10000);
      }
    },

    closePopup() {
      this.openNotificationPopup = false;
    },

    acceptBuddy(id) {
      // Ew, fix
      const nameSplit = this.notificationPopup.object.Description.split(' ');
      this.buddy = { target: id, name: `${nameSplit[0]} ${nameSplit[1]}` };
      this.openAcceptBuddy = true;
    },

    declineBuddy(id) {
      // Same here
      const nameSplit = this.notificationPopup.object.Description.split(' ');
      this.buddy = { target: id, name: `${nameSplit[0]} ${nameSplit[1]}` };
      this.openDeclineBuddy = true;
    },

    closeAcceptBuddy() {
      this.openAcceptBuddy = false;
    },

    closeDeclineBuddy() {
      this.openDeclineBuddy = false;
    },
  },

};

</script>

<style>
  .bounce {
    -webkit-animation: bounce 1.2s infinite alternate;
    -moz-animation: bounce 1.2s infinite alternate;
    animation: bounce 1.2s infinite alternate;
  }
  @-webkit-keyframes bounce {
    to { -webkit-transform: scale(4.8); }
  }
  @-moz-keyframes bounce {
    to { -moz-transform: scale(4.8); }
  }
  @keyframes bounce {
    to { transform: scale(4.8); }
  }

  @media only screen and (max-width: 923px) {
    .header{
      background-color: teal;
      color: #FFFFFF;
      position: fixed;
      width: 100%;
      height: 65px;  transition: transform 0.25s ease-in-out;
    }

    .header-unpin{
      /* display: none; */
      transform: translateY(-65px);
    }
    .header-pin{
      /* display: block; */
      transform: translateY(0);
    }
  }

  .rotate {
    animation: rotation 1s infinite linear;
  }

  @keyframes rotation {
    0% {
      transform: rotate(-360deg);
    }
  }

  @media (max-width: 768px){
    .bellIcon:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    margin-top: 5px;
    color: transparent;
    border: 10px solid rgb(0, 0, 0);
    border-color: transparent transparent #e2e8f0;
  }

}
</style>
