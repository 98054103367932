const giftCardStore = {
  state: {
    enteredCode: false,
  },

  mutations: {
    setEnteredCode(state) {
      state.enteredCode = true;
    },
  },

  actions: {
    setEnteredCode(context) {
      return new Promise(() => {
        context.commit('setEnteredCode');
      });
    },
  },
};

export { giftCardStore };
