import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import { themeStore } from './themeStore';
// eslint-disable-next-line import/no-cycle
import { userStore } from './userStore';
import { clubStore } from './clubStore';
import { basketStore } from './basketStore';
import { layoutStore } from './layoutStore';
import { packagesStore } from './packagesStore';
import { buddyStore } from './buddyStore';
import { settingsStore } from './settingsStore';
import { stateStore } from './stateStore';
import { giftCardStore } from './giftCardStore';
import { contentStore } from './contentStore';

const persistenceBit = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    user: {
      isAuthenticated: state.user.isAuthenticated,
      homeClubId: state.user.homeClubId,
      homeClubName: state.user.homeClubName,
      locale: state.user.locale,
      membershipExpires: state.user.membershipExpires,
    },
    packages: {
      packages: state.packages.packages,
      lastFetch: state.packages.lastFetch,
    },
    buddy: {
      shareCode: state.buddy.shareCode,
    },
    settings: {
      textSize: state.settings.textSize,
      calendarSync: state.settings.calendarSync,
    },
    state: {
      blackFriday: state.state.blackFriday,
      hasCompletedTour: state.state.hasCompletedTour,
    },
    giftCard: {
      enteredCode: state.giftCard.enteredCode,
    },
  }),
});

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    user: userStore,
    theme: themeStore,
    basket: basketStore,
    club: clubStore,
    layout: layoutStore,
    packages: packagesStore,
    buddy: buddyStore,
    settings: settingsStore,
    state: stateStore,
    giftCard: giftCardStore,
    content: contentStore,
  },
  plugins: [persistenceBit.plugin],
});

export { store };
