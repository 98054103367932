<template>
  <div
    v-if="numberOfNotifications > 0"
    class="inline-block text-xs text-white bg-red-700 absolute leading-none cursor-pointer select-none"
    style="top: 0px; right: -10px; padding: 1px 5px; border-radius: 50%;"
  >
    {{ numberOfNotifications }}
  </div>
</template>

<script>
export default {
  props: {
    numberOfNotifications: {
      type: Number,
      default: 0,
    },
  },
};

</script>
