import { MemberSummaryModel, TimeUntilExpiryModel } from 'pmg-services';
import i18n, { selectedLocale } from '@/i18n';
// eslint-disable-next-line import/no-cycle
import router from '@/app/app-routes';
import { services } from '../services';

function expiringSoon(expiry) {
  const expiryDate = new Date(expiry);
  expiryDate.setMonth(expiryDate.getMonth() - 2);

  const currentDate = new Date();
  if (expiryDate <= currentDate) {
    return true;
  }

  return false;
}

function initNotifications(id) {
  // eslint-disable-next-line func-names
  const notificationOpenedCallback = function (jsonData) {
    switch (jsonData.notification.payload.additionalData.Type) {
      case '0': {
        router.push('/bookings');
        break;
      }
      case '1': {
        router.push('/trophies');
        break;
      }
      case '2': {
        router.push('/buddies');
        break;
      }
      case '3': {
        router.push('/profile');
        break;
      }
      default: {
        router.push('/');
      }
    }
  };

  if (window.plugins !== undefined) {
    // Set your iOS Settings
    const iosSettings = {};
    iosSettings.kOSSettingsKeyAutoPrompt = false;
    iosSettings.kOSSettingsKeyInAppLaunchURL = false;


    window.plugins.OneSignal.startInit('c0710ff2-1531-479d-848e-2adc12bbae4a')
      .handleNotificationOpened(notificationOpenedCallback)
      .iOSSettings(iosSettings)
      .inFocusDisplaying(window.plugins.OneSignal.OSInFocusDisplayOption.Notification)
      .endInit();
    // The promptForPushNotificationsWithUserResponse function will show the iOS push notification prompt. We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step 6)
    window.plugins.OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
      console.log(`User accepted notifications: ${accepted}`);
    });

    window.plugins.OneSignal.setExternalUserId(id);
  }
}

const userStore = {
  state: {
    homePoints: 0,
    flexiPoints: 0,
    refreshingPoints: true, // true because it happens on mount
    userGuid: '',
    username: '',
    forename: '',
    surname: '',
    contactNumber: '',
    homeClubId: 0,
    homeClubName: '',
    membershipType: null,
    completedFirstPayment: false,
    membershipExpires: null,
    isCorporateMember: false,
    companyName: '',
    bannerUrl: '',
    thumbUrl: '',
    autoRenewOn: false,
    isAdmin: false,
    isInArrears: false,
    // these 2 capital i's are upsetting
    IsExpired: false,
    IsImpersonating: false,
    refreshingMemberSummary: true, // true because it happens on mount
    refreshingMemberSummaryError: false,
    pointlessIncrement: 0,
    notPersistedIncrement: 0,
    isAuthenticated: false,
    flexiPointsAllowed: true,
    packageName: undefined,
    locale: selectedLocale,
    expiringSoon: false,
    tier: undefined,
    achievementScore: 0,
    someFormOfExperienceMembership: false,
    canChangeHomeClub: false,
  },

  getters: {
    // this is out of date now, it doesnt have expiry info in it
    getMemberSummary: state => new MemberSummaryModel(
      0,
      state.userGuid,
      state.username,
      state.forename,
      state.surname,
      state.contactNumber,
      state.homeClubId,
      state.homeClubName,
      state.homePoints,
      state.flexiPoints,
      state.membershipType,
      state.completedFirstPayment,
      state.membershipExpires,
      state.isCorporateMember,
      state.companyName,
      state.bannerUrl,
      true,
      state.thumbUrl,
      state.autoRenewOn,
      state.isAdmin,
      state.isInArrears,
      state.IsExpired,
      state.IsImpersonating,
      state.flexiPointsAllowed,
      state.packageName,
      state.tier,
      state.someFormOfExperienceMembership,
      state.canChangeHomeClub,
    ),

    getMemberExpiryInfo: state => new TimeUntilExpiryModel(
      state.membershipExpires,
    ),

    isExperienceMember: state => state.membershipType === 2 || state.someFormOfExperienceMembership || state.homeClubId === 196,
  },

  mutations: {
    setMemberSummaryError(state, value) {
      state.refreshingMemberSummaryError = value;
    },
    setRefreshingPoints(state, value) {
      state.refreshingPoints = value;
    },
    setHomePoints(state, value) {
      state.homePoints = value;
    },
    setFlexiPoints(state, value) {
      state.flexiPoints = value;
    },
    pushMemberSummary(state, memberSummary) {
      state.userGuid = memberSummary.UserGuid;
      state.username = memberSummary.Username;
      state.forename = memberSummary.Forename;
      state.surname = memberSummary.Surname;
      state.contactNumber = memberSummary.ContactNumber;
      state.homeClubId = memberSummary.HomeClubId;
      state.homeClubName = memberSummary.HomeClubName;
      state.membershipType = memberSummary.MembershipType;
      state.completedFirstPayment = memberSummary.CompletedFirstPayment;
      state.membershipExpires = memberSummary.MembershipExpires;
      state.isCorporateMember = memberSummary.IsCorporateMember;
      state.companyName = memberSummary.CompanyName;
      state.bannerUrl = memberSummary.BannerUrl;
      state.thumbUrl = memberSummary.ThumbUrl;
      state.autoRenewOn = memberSummary.AutoRenewOn;
      state.isAdmin = memberSummary.IsAdmin;
      state.isInArrears = memberSummary.IsInArrears;
      state.IsExpired = memberSummary.IsExpired;
      state.IsImpersonating = memberSummary.IsImpersonating;
      state.flexiPointsAllowed = memberSummary.FlexiPointsAllowed;
      state.packageName = memberSummary.packageName;
      state.expiringSoon = expiringSoon(memberSummary.MembershipExpires);
      state.tier = memberSummary.Tier;
      state.someFormOfExperienceMembership = memberSummary.SomeFormOfExperienceMembership;
      state.refreshingMemberSummaryError = false;
      state.canChangeHomeClub = memberSummary.CanChangeHomeClub;

      try {
        if (this.$isStandard) {
          initNotifications(memberSummary.UserGuid);
        }
      } catch (error) {
        console.log(error);
      }

      if (!this.$isMobileApp && memberSummary.IsAdmin) {
        // window.location = process.env.VUE_APP_ADMIN_URL;
      }
    },

    emptyMemberSummary(state) {
      state.userGuid = '';
      state.username = '';
      state.forename = '';
      state.surname = '';
      state.contactNumber = '';
      state.homeClubId = 0;
      state.homeClubName = '';
      state.membershipType = null;
      state.completedFirstPayment = false;
      state.membershipExpires = null;
      state.isCorporateMember = false;
      state.companyName = '';
      state.bannerUrl = '';
      state.thumbUrl = '';
      state.autoRenewOn = false;
      state.isAdmin = false;
      state.isInArrears = false;
      state.IsExpired = false;
      state.IsImpersonating = false;
      state.flexiPointsAllowed = false;
      state.packageName = undefined;
      state.tier = undefined;
      state.someFormOfExperienceClub = false;
    },
    setRefreshingMemberSummary(state, value) {
      state.refreshingMemberSummary = value;
    },
    setIsAuth(state, value) {
      state.isAuthenticated = value;
    },
    updateLocale(state, newLocale) {
      state.locale = newLocale;
    },
    setAchievementScore(state, value) {
      state.achievementScore = value;
    },
  },

  actions: {
    setAuth(context, value) {
      context.commit('setIsAuth', value);
    },

    async refreshPoints({ commit }, realtime = false) {
      commit('setRefreshingPoints', true);
      await services.member.getMemberPoints(realtime).then(
        (memberPoints) => {
          commit('setHomePoints', memberPoints.homePoints);
          commit('setFlexiPoints', memberPoints.flexiPoints);
          commit('setRefreshingPoints', false);
        },
        (error) => {
          console.log(error);
        },
      );
    },

    async refreshPointsUntilUpdate({ commit, dispatch, state }) {
      await services.member.getMemberPoints(true).then((points) => {
        if (points.homePoints !== state.homePoints || points.flexiPoints !== state.flexiPoints) {
          commit('setHomePoints', points.homePoints);
          commit('setFlexiPoints', points.flexiPoints);
        } else {
          setTimeout(() => { dispatch('refreshPointsUntilUpdate'); }, 1000);
        }
      });
    },

    async emptyMemberSummary({ commit }) {
      commit('emptyMemberSummary');
    },

    async populateMemberSummary(context) {
      return new Promise((resolve) => {
        services.account.cookieAuthenticated().then((data) => {
          context.commit('setRefreshingPoints', true);
          context.commit('setRefreshingMemberSummary', true);
          context.commit('setMemberSummaryError', false);
          if (data) {
            services.member.memberSummary().then((summary) => {
              if (summary !== undefined) {
                context.commit('setIsAuth', true);
                if (summary.Tier === 'Standard' || summary.Tier === null) {
                  context.dispatch('getClubDetails', summary.HomeClubId);
                }

                if (summary.IsInArrears) {
                  context.commit('pushMemberSummary', summary);
                  router.push('/arrears');
                  resolve();
                } else {
                  context.commit('pushMemberSummary', summary);
                  resolve();
                }

                context.commit('setHomePoints', summary.HomePoints);
                context.commit('setFlexiPoints', summary.AwayPoints);
              } else if (context.state.isAuthenticated) {
                context.commit('setIsAuth', false);
                location.reload();
              }

              context.commit('setRefreshingPoints', false);
              context.commit('setRefreshingMemberSummary', false);
              services.member.memberAchievementScore().then((score) => {
                context.commit('setAchievementScore', score);
              });

              context.dispatch('populateShareCode');
            }).catch(() => {
              context.commit('setMemberSummaryError', true);
            });
          } else {
            context.commit('setIsAuth', false);
            context.commit('setRefreshingPoints', true);
            context.commit('setRefreshingMemberSummary', true);
            // location.reload();
          }
        });
      });
    },

    changeLocale({ commit }, newLocale) {
      i18n.locale = newLocale;
      commit('updateLocale', newLocale);
    },

  },

};

export { userStore };
