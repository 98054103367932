const frMessages = {
  fr: {
    tabs: {
      home: 'Homefr',
      explore: 'Explorefr',
      buddies: 'Buddiesfr',
      trophies: 'Trophiesfr',
      help: 'Helpfr',
      account: 'Accountfr',
    },
    home: {
      homeMenu: {
        bookings: 'Bookingsfr',
        dashboard: 'Dashboardfr',
        renew: 'Renewfr',
        referrals: 'Referralsfr',
        statistics: 'Statisticsfr',
        topUp: 'Top-upfr',
      },
    },
    commonUi: {
      login: 'Sign Infr',
      forgotPassword: 'Forgot Password?fr',
      emailAddress: 'Email Addressfr',
      password: 'Passwordfr',
      resetPassword: 'Reset Passwordfr',
      backToLogin: 'Back to Loginfr',
      empty: 'Emptyfr',
      close: 'Closefr',
      accept: 'Acceptfr',
      decline: 'Declinefr',
      cancel: 'Cancelfr',
      back: 'Backfr',
      confirm: 'Confirmfr',
      search: 'Searchfr',
      sortBy: 'Sort byfr',
    },
    login: {
      noAccount: "Don't have an account?",
      signUp: 'Sign up',
      signInMessage: 'Sign in to Your Accountfr',
      signInMessageClubSpecific: 'Sign in to {0}fr',
      incorrectDetails: 'Sorry, your email address or password is incorrect.fr',
      resetPasswordMessageClubSpecific: 'Reset Your Password for {0}fr',
      passwordResetInfo: "We'll send a password reset link to your email address",
      passwordReset: " If the email address {0} exists in our system, we've sent you instructions to reset your password.fr",
    },
    explore: {
      clubList: {
        miles: 'milesfr',
        sortBy: {
          bestMatch: 'Best matchfr',
          distance: 'Distancefr',
          aZ: 'A-Zfr',
        },
      },
      teeTimes: {
        teeTimes: 'Tee timesfr',
        backToExplore: 'Back to Explorefr',
        website: 'Websitefr',
        pointsMatrix: 'Points Matrixfr',
        address: 'Addressfr',
        previousDay: 'Previous dayfr',
        nextDay: 'Next dayfr',
        time: 'Timefr',
        price: 'Pricefr',
        backToFilters: 'Back to Search Filtersfr',
        morningTeeTimes: 'Morning @:explore.teeTimes.teeTimesfr',
        afternoonTeeTimes: 'Afternoon @:explore.teeTimes.teeTimesfr',
        eveningTeeTimes: 'Evening @:explore.teeTimes.teeTimesfr',
        noTeeTimesAvailable: 'No tee times available forfr',
        noTeeTimesAvailableHelp: "Sorry, we couldn't find any available tee times for the selected date. Please try another.fr",
        noPlayerSlotsAvailable: 'No player slots remaining forfr',
        noPlayerSlotsAvailableHelp: 'Sorry, you no longer have any player slots availble for the selected date. Please try another.fr',
      },
    },
    buddies: {
      buddyMenu: {
        buddies: 'Buddiesfr',
        requests: 'Requestsfr',
        pending: 'Pendingfr',
      },
      shareCode: {
        label: 'Your buddy share codefr',
        title: 'What is a share code?fr',
        subHeading: 'A share code helps your buddy find you.fr',
        p1: 'You can give this sharing code to your friends to make it easy for them to send a buddy request to you.fr',
        p2: 'Ask them to use this code in the "Add Buddy" section on their Buddy Management page.fr',
        p3: "Even with the Share Code, a friend's buddy request needs to be confirmed by you before you'll become Buddies.fr",
        p4: 'For security and privacy reasons we recommend you use your unique Share Code instead of sharing your email address.fr',
      },
      management: {
        unbuddy: 'Unbuddyfr',
        cancelRequestDialogue: {
          header: 'Cancel Buddy Requestfr',
          message: 'Are you sure you want to cancel your buddy request to {0}?fr',
        },
        removeDialogue: {
          header: 'Remove Buddyfr',
          message: 'Are you sure you want to unbuddy {0}?fr',
        },
        acceptDialogue: {
          header: 'Accept Buddyfr',
          message: 'Are you sure you want to accept this Buddy invitation from {0}?fr',
          listHeader: 'This will mean {0} will be able to:fr',
          list1: 'Add you as a player (Buddy) to a booking, using your points, without confirmationfr',
          list2: 'Know if you have insufficient points to make a booking, and have the option to use their points insteadfr',
          list3: 'and you will be able to do the samefr',
          listEnd: 'Additionally, any player (Buddy) added to a booking will have the option to cancel the booking (subject to normal cancellation terms) which will cancel and refund the entire booking.fr',
          listWarning: "You shouldn't accept buddy invitations from people you don't know. You can revoke Buddy priviledges at any time.fr",
        },
        declineDialogue: {
          header: 'Decline Buddy Requestfr',
          message: 'Are you sure you want to decline your buddy request from {0}?fr',
        },
        findDialogue: {
          header: 'Find a Buddyfr',
          message: "To add a buddy you'll need their email address or share code (If you don't know it, ask your friend to send it to you).fr",
          couldntFind: "Sorry, we couldn't find anyone using that share code or email address.fr",
          placeholder: 'Buddy share code or email addressfr',
          findBuddy: 'Find Buddyfr',
          buddyFound: 'Buddy Foundfr',
          listHeader: "We've found a match. Are you sure you want to send {fullName} a Buddy Request? {firstName} will need to confirm this request. Once confirmed, {firstName} will be able to:fr",
          list1: '@buddies.management.acceptBuddy.list1',
          list2: '@buddies.management.acceptBuddy.list2',
          list3: '@buddies.management.acceptBuddy.list3',
          listEnd: '@buddies.management.acceptBuddy.listEnd',
        },
      },
    },
    booking: {
      players: 'Playersfr',
      juniors: 'Juniorsfr',
      points: 'Pointsfr',
    },
    payment: {

    },
  },
};
export default frMessages;
