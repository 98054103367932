import { themeList } from './lists/themeList';

const themeStore = {
  state: {
    showLayout: true,
    squeezePage: false,
    hideHomeClub: false,
    overrideStyle: false,
    hideGlobalLoader: false,
    overridingClubId: 0,
  },

  getters: {
    //  uses themeList instead of state because should never need to set a theme manually maybe
    getCurrentTheme: (state, getters, rootState) => {
      if (state.overrideStyle) {
        return themeList.find(theme => theme.clubId === state.overridingClubId) !== undefined
          ? themeList.find(theme => theme.clubId === state.overridingClubId)
          : themeList.find(theme => theme.clubId === 0);
      }
      return themeList.find(theme => theme.clubId === rootState.user.homeClubId) !== undefined
        ? themeList.find(theme => theme.clubId === rootState.user.homeClubId)
        : themeList.find(theme => theme.clubId === 0);
    },
  },

  mutations: {
    setTheme(state, id) {
      state.currentThemeId = id;
    },

    toggleLayout(state, showLayout) {
      state.showLayout = showLayout;
    },
    toggleHideGlobalLoader(state, hideGlobalLoader) {
      state.hideGlobalLoader = hideGlobalLoader;
    },
    toggleSqueezePage(state, squeezePage) {
      state.squeezePage = squeezePage;
    },
    toggleHideHomeClub(state, hideHomeClub) {
      state.hideHomeClub = hideHomeClub;
    },
    setOverrideStyle(state, value) {
      state.overrideStyle = value;
    },
    setOverridingClubId(state, value) {
      if (!(isNaN(value))) {
        state.overrideStyle = true;
        state.overridingClubId = Number(value);
      } else {
        state.overridingClubId = 0;
      }
    },
  },

  actions: {

  },
};

export { themeStore };
