<template>
  <div class="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-40">
    <div class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-500 opacity-50" />
    </div>

    <div class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6 ">
      <div class="sm:flex sm:items-start">
        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <svg
            class="h-6 w-6 text-red-600"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 class="text-lg leading-6 font-medium text-gray-900 brand-heading">
            {{ title }}
          </h3>
          <div class="mt-2">
            <p class="text-sm leading-5 text-gray-500 brand-body">
              {{ message }}
            </p>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <span
          class="flex w-full shadow-sm sm:ml-3 sm:w-auto"
          @click="confirm"
        >
          <button
            type="button"
            class="brand-button w-full text-center bg-red-700 hover:bg-red-600"
          >
            <span v-if="!loading">{{ $t('commonUi.confirm') }}</span>
            <LoadingSpinner
              v-if="loading"
              class="-mt-2"
              :white="true"
            />
          </button>
        </span>
        <span
          class="mt-3 flex w-full sm:mt-0 sm:w-auto"
          @click="cancel"
        >
          <button
            type="button"
            class="brand-button-secondary w-full text-center"
          >
            {{ $t('commonUi.cancel') }}
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '../loadingSpinner.vue';

export default {

  components: {
    LoadingSpinner,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {

    };
  },

  methods: {
    confirm() {
      this.$emit('confirm');
      this.$emit('result', true);
    },

    cancel() {
      this.$emit('cancel');
      this.$emit('result', false);
    },
  },
};
</script>
