const renewRoutes = [
  {
    path: '/membership/transfer',
    name: 'membershipTransfer',
    component: () => import('@/app/renew/transfer.vue'),
    meta: {
      title: 'Transfer Home Club',
      requiresAuth: true,
      isWindowView: true,
      windowViewTitle: 'Transfer Home Club',
      hideFooter: true,
    },
  },
  {
    path: '/membership/pmg-plus',
    name: 'pmgPlus',
    component: () => import('@/app/renew/PMGPlus.vue'),
    meta: {
      title: 'PlayMoreGolf Plus',
      requiresAuth: true,
      isWindowView: true,
      windowViewTitle: 'PlayMoreGolf Plus',
      hideFooter: true,
    },
  },
];

export default renewRoutes;
